import { useContext, useState } from "react";
import { mutate } from "swr";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { Button, Flash, Text } from "rimble-ui";
import { ModalContent, ModalHeader, SertoUiContext } from "serto-ui";
import { ErrorMsgSettings } from "../../components";

export interface APIKeyManagementDeleteProps {
  apiName: string;
  close(): void;
}

export const APIKeyManagementDelete: React.FunctionComponent<APIKeyManagementDeleteProps> = (props) => {
  const { toastProvider } = useContext(SertoUiContext);
  const SertoAgent = useContext(SertoAgentContext);
  const [error, setError] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const deleteApiKey = async () => {
    setError("");
    setLoading(true);

    try {
      await SertoAgent.deleteApiKey(props.apiName);
      mutate(SertoAgent.api.API_KEYS);
      toastProvider.addMessage("API Key " + props.apiName + " has been deleted", {
        colorTheme: "light",
        variant: "success",
      });
      props.close();
      return;
    } catch (err) {
      console.error("failed to delete api key:", err);
      setError(<ErrorMsgSettings error={err.message} />);
      setLoading(false);
    }
  };

  return (
    <>
      <ModalHeader>Delete API Key</ModalHeader>
      <ModalContent>
        <Text fontSize={1} fontWeight={3} mb={2}>
          API Key
        </Text>
        <Text mb={3}>{props.apiName}</Text>
        {error && (
          <Flash mb={3} variant="danger">
            {error}
          </Flash>
        )}
        <Button onClick={deleteApiKey} disabled={loading} variant="danger" width="100%">
          Delete
        </Button>
      </ModalContent>
    </>
  );
};
