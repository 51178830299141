import { useContext, useState } from "react";
import useSWR, { mutate } from "swr";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { domainRegex } from "../../constants";
import { DidConfigDownloadBtn } from "../../components/elements/";
import { DidConfigSuccess } from "../../components/text/";
import { CheckCircle } from "@rimble/icons";
import { Box, Button, Flash, Flex, Form, Input, Loader } from "rimble-ui";
import { baseColors, colors, H3, H5 } from "serto-ui";
import { DidConfigExplainer, ErrorMsgDidConfig, FormField, FormLabel, FormLabelDescription } from "../../components";
import { DidCheckBox } from "./DidCheckBox";

export interface DidConfigCreateProps {
  close(): void;
}

export const DidConfigCreate: React.FunctionComponent<DidConfigCreateProps> = (props) => {
  const SertoAgent = useContext(SertoAgentContext);
  const { data: identifiers } = useSWR(SertoAgent.api.VERAMO_GET_DIDS, () => SertoAgent.getDids(), {
    revalidateOnFocus: false,
  });
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [configDomain, setConfigDomain] = useState<string>("");

  async function createDidConfig(e: Event) {
    e.preventDefault();
    setError("");
    setLoading(true);

    const domain: any = document.querySelector("input[name=domain]");
    if (domain.value === "") {
      setError("Domain is required");
      setLoading(false);
      return;
    } else if (!domainRegex.test(domain.value)) {
      setError("Domain is not vaild");
      setLoading(false);
      return;
    }
    setConfigDomain(domain.value);

    const selectedDids = document.querySelectorAll("input[name=dids]:checked");
    let dids: any = [];
    if (selectedDids.length === 0) {
      setError("At least one DID is required");
      setLoading(false);
      return;
    } else {
      dids = Array.from(selectedDids).map((checkbox: any) => checkbox.value);
    }

    try {
      await SertoAgent.getDidConfig(domain.value);
      setError("This domain already exists. To edit this domain select View/Edit.");
    } catch (err) {
      console.error("domain doesn't exist:", err);
      try {
        await SertoAgent.createDidConfig(domain.value, dids);
        setSuccess(true);
        mutate(SertoAgent.api.DID_CONFIG);
      } catch (err) {
        console.error("failed to create did config:", err);
        setError(<ErrorMsgDidConfig error={err.message} />);
        setLoading(false);
        return;
      }
    }
    setLoading(false);
  }

  if (success) {
    return (
      <>
        <H3 mb={3} mt={0}>
          Upload your DID Configuration file to your domain
        </H3>
        <H5>
          <Flex alignItems="center">
            <CheckCircle color={colors.success.base} mr={2} /> Your DID Config file has been created
          </Flex>
        </H5>
        <Box borderBottom={1} mb={5} pb={5}>
          <DidConfigDownloadBtn domain={configDomain} width="300px" />
        </Box>
        <DidConfigSuccess />
        <Button onClick={props.close} width="100%">
          Done
        </Button>
      </>
    );
  }

  return (
    <>
      <H3 mb={3} mt={0}>
        Generate your DID Configuration File
      </H3>
      <Form onSubmit={createDidConfig}>
        <Box>
          <DidConfigExplainer />
          <FormField>
            <FormLabel>Your Domain *</FormLabel>
            <FormLabelDescription>
              This should be the domain where you intend to host your DID configuration file
            </FormLabelDescription>
            <Input name="domain" placeholder="ex: acme.com" required type="text" width="100%" />
          </FormField>
          <FormField>
            <FormLabel>Select identifiers *</FormLabel>
            {identifiers && identifiers.length > 0 && (
              <>
                <FormLabelDescription>
                  Selecting an identifier will result in it being added to your DID configuration file
                </FormLabelDescription>
                <Box overflow="scroll" maxHeight="160px">
                  {identifiers?.map((identifier: any, i: number) => {
                    return <DidCheckBox did={identifier} name="dids" key={i} />;
                  })}
                </Box>
              </>
            )}
          </FormField>
        </Box>
        {error && (
          <Flash mb={3} variant="danger">
            {error}
          </Flash>
        )}
        <Button disabled={loading} type="submit" width="100%">
          {loading ? <Loader color={baseColors.white} /> : "Generate DID Config"}
        </Button>
      </Form>
    </>
  );
};
