import { useContext } from "react";
import useSWR from "swr";
import { SertoAgentContext } from "../../../context/SertoAgentProvider";
import { Box, Button, Text } from "rimble-ui";
import { colors, H4, H5 } from "serto-ui";
import {
  DidConfigDownloadBtn,
  DidConfigStatus,
  DidConfigSuccess,
  IsSuccessInline,
  OnboardingWidget,
} from "../../../components";

export interface DownloadDIDConfigProps {
  domain: string;
  onboardingFlow: string;
  onComplete(): void;
}

export const DownloadDIDConfig: React.FunctionComponent<DownloadDIDConfigProps> = (props) => {
  const SertoAgent = useContext(SertoAgentContext);
  const { data } = useSWR(SertoAgent.api.DID_CONFIG, () => SertoAgent.refreshDidConfigStatus(props.domain), {
    revalidateOnFocus: false,
  });

  const checkStatus = (status: string) => {
    if (status === "synced") {
      props.onComplete();
    }
  };

  return (
    <OnboardingWidget
      onboardingFlow={props.onboardingFlow}
      step={5}
      stepTitleNumber={3}
      title="Link identifier (DID) to organization’s domain"
    >
      <Box borderBottom={1} maxWidth="500px" mb={5} pb={6}>
        <H5>
          <IsSuccessInline text="Your DID Config file has been created" />
        </H5>
        <DidConfigDownloadBtn domain={props.domain} width="300px" />
      </Box>
      <Box maxWidth="600px">
        <H4 mb={1}>Next: Upload Your DID configuration file to website</H4>
        <Text fontSize={1} mb={5}>
          To complete the linking, the DID configuration file must be hosted on your organization’s website. Download
          the DID configuration file, and then upload it to where your website is hosted.
        </Text>
        <DidConfigSuccess />
      </Box>
      <Box borderTop={2} maxWidth="600px" my={4} pt={3}>
        <Text color={colors.midGray} fontSize={1} mb={4}>
          After you upload the DID configuration file to your website, check sync status below.
        </Text>
        {data && (
          <DidConfigStatus
            domain={props.domain}
            status={data.status}
            getUpdatedStatus={(status) => checkStatus(status)}
          />
        )}
      </Box>
      {data && data.status === "synced" && (
        <Button onClick={() => props.onComplete()} maxWidth="300px" width="100%">
          Next
        </Button>
      )}
    </OnboardingWidget>
  );
};
