import { useContext } from "react";
import useSWR from "swr";
import { SertoUiProvider, SertoUiContextInterface } from "serto-ui";

import { SertoAgentContext } from "./SertoAgentProvider";

export interface SertoUiWrapperProps {
  navItems: SertoUiContextInterface["navItems"];
}

export const SertoUiWrapper: React.FunctionComponent<SertoUiWrapperProps> = (props) => {
  const SertoAgent = useContext(SertoAgentContext);
  const { data: dids, error } = useSWR("/v1/agent/didManagerFind", () => SertoAgent.getDids(), {
    revalidateOnFocus: false,
  });
  if (error) {
    console.error("SertoUiWrapper failed to retrieve DIDs:", error);
  }

  const sertoUiContext = {
    navItems: props.navItems,
    userDids: dids,
    issueVc: SertoAgent.issueVc.bind(SertoAgent),
    sendVc: SertoAgent.sendVc.bind(SertoAgent),
  };

  return <SertoUiProvider context={sertoUiContext}>{props.children}</SertoUiProvider>;
};
