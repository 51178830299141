import { Box, Flex, Text } from "rimble-ui";
import { colors, CopyToClipboard, DidMethodIcon, DidTruncate } from "serto-ui";

export interface DidViewAliasProps {
  alias?: string;
  copy?: boolean;
  did: string;
  inline?: boolean;
}

export const DidViewAlias: React.FunctionComponent<DidViewAliasProps> = (props) => {
  const { alias, copy, did, inline } = props;
  const isDidWeb = did.includes("did:web");

  return (
    <Box pl="40px" position="relative" width="100%">
      <Flex alignItems="flex-start" justifyContent="center" left={0} position="absolute" width="40px">
        <DidMethodIcon did={did} size="24px" />
      </Flex>
      {!isDidWeb && alias ? (
        <>
          {inline ? (
            <Flex alignItems="center">
              <Text color={colors.midGray} fontSize={2} mr={2}>
                {alias}
              </Text>
              <Text color={colors.silver} fontSize={1} maxWidth="calc(100% - 20px)" title={did}>
                <DidTruncate did={did} />
              </Text>
              {copy && <DidViewAliasCopy did={did} />}
            </Flex>
          ) : (
            <Box>
              <Text color={colors.midGray} fontSize={2}>
                {alias}
              </Text>
              <Flex alignItems="center">
                <Text color={colors.silver} fontSize={1} maxWidth="calc(100% - 20px)" title={did}>
                  <DidTruncate did={did} />
                </Text>
                {copy && <DidViewAliasCopy did={did} />}
              </Flex>
            </Box>
          )}
        </>
      ) : (
        <Flex alignItems="center">
          <Text color={colors.midGray} fontSize={2} maxWidth="calc(100% - 20px)" title={did}>
            <DidTruncate did={did} />
          </Text>
          {copy && <DidViewAliasCopy did={did} />}
        </Flex>
      )}
    </Box>
  );
};

export const DidViewAliasCopy: React.FunctionComponent<DidViewAliasProps> = (props) => {
  const { did } = props;
  return (
    <Box ml={1}>
      <CopyToClipboard color={colors.primary.base} hoverTitle="Copy DID" size="14px" text={did} />
    </Box>
  );
};
