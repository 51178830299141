import { Box, Flex } from "rimble-ui";
import { H3 } from "serto-ui";

// TODO(sruddy): Update in serto-ui after designs are finshed

export interface SecondaryHeaderProps {
  heading: string;
  cta?: JSX.Element;
}

export const SecondaryHeader: React.FunctionComponent<SecondaryHeaderProps> = (props) => {
  return (
    <Box mb={4} mt={5} mx={4}>
      <Flex alignItems="center" justifyContent="space-between">
        <H3 mb={2} mt={0}>
          {props.heading}
        </H3>
        {props.cta}
      </Flex>
      {props.children}
    </Box>
  );
};
