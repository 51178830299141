import { useContext, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { routes } from "../../constants";
import { Box, Button, Field, Flash, Form, Input, Link } from "rimble-ui";
import { LoginLayout } from "../../components/layout";
import { ErrorMsgAuth } from "../../components/text";

export const LoginPage = (): JSX.Element => {
  const SertoAgent = useContext(SertoAgentContext);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<any | undefined>();
  const history = useHistory();

  async function login() {
    try {
      const succeeded = await SertoAgent.login(username, password);
      if (succeeded) {
        history.push(routes.HOMEPAGE);
      }
    } catch (err) {
      console.error("error logging in:", err);
      setError(<ErrorMsgAuth error={err.message} />);
    }
  }

  if (SertoAgent.isAuthenticated()) {
    return <Redirect to={routes.HOMEPAGE} />;
  }

  return (
    <LoginLayout showTerms={true}>
      <Form
        width="100%"
        onSubmit={(e: any) => {
          e.preventDefault();
          login();
        }}
      >
        <Field width="100%" label="Username">
          <Input
            placeholder="Enter username"
            type="text"
            width="100%"
            required
            onChange={(e: any) => setUsername(e.target.value)}
          />
        </Field>
        <Field width="100%" label="Password">
          <Input
            placeholder="Enter password"
            type="password"
            width="100%"
            required
            onChange={(e: any) => setPassword(e.target.value)}
          />
        </Field>
        {error && (
          <Flash mb={3} variant="danger">
            {error}
          </Flash>
        )}
        <Button mb={2} type="submit" width="100%">
          Login
        </Button>
      </Form>
      <Box mb={3}>
        <Link href={routes.FORGOT_PASSWORD}>Forgot Password?</Link>
      </Box>
    </LoginLayout>
  );
};
