import { Link, Text } from "rimble-ui";
import { colors } from "serto-ui";
import { routes } from "../../constants";

export const RequiredField: React.FunctionComponent = () => (
  <Text.span color={colors.silver} display="block" fontSize={1} mb={3}>
    * Indicates a required field
  </Text.span>
);

export const AliasExplainerText: React.FunctionComponent = () => (
  <>
    <Text color={colors.silver} fontSize={1} mt={3}>
      An alias is a string that you give to a DID to provide addtional detail. It can be as simple as a nickname or can
      contain more contextual information such as a mapping to an external ID. Aliases can be edited at any time.
    </Text>
  </>
);

export const DIDWebDomainExplainerText: React.FunctionComponent<{ domain?: string; agentDomain: string }> = ({
  domain,
  agentDomain,
}) => {
  const didDocUrl = `https://${domain || "domain.com"}/.well-known/did.json`;
  return (
    <>
      <Text color={colors.silver} fontSize={1} mt={3}>
        Each did:web is connected to a domain name. In order for your did:web to resolve, its DID Document must be
        hosted at{" "}
        <Link href={didDocUrl} target="_blank">
          {didDocUrl}
        </Link>
        . Serto Agent will do this for you at the domain it serves ({agentDomain}).
      </Text>
    </>
  );
};

export const NoAPIKeysText: React.FunctionComponent = () => (
  <Text.span display="block" fontSize={1} lineHeight="copy" textAlign="center">
    <b style={{ display: "block", fontWeight: 600 }}>You do not have any api keys.</b>
    Create an API Key to use Serto Agent programatically.
  </Text.span>
);

export const NoDIDsText: React.FunctionComponent = () => (
  <Text.span display="block" fontSize={1} lineHeight="copy" textAlign="center">
    <b style={{ display: "block", fontWeight: 600 }}>You do not have any DIDs.</b>
    Create a DID so you can issue and receive credentials.
  </Text.span>
);

export const DIDConfigRequiresDIDText: React.FunctionComponent = () => (
  <Text.span display="block" fontSize={1} lineHeight="copy" textAlign="center">
    <b style={{ display: "block", fontWeight: 600 }}>You do not have any DIDs.</b>
    DIDs are required before you can create a DID configuration. You can create one in{" "}
    <Link href={routes.IDENTIFIERS_MY_DIDS}>My DIDs</Link>.
  </Text.span>
);

export const NoDIDConfigsAdminText: React.FunctionComponent = () => (
  <Text.span display="block" fontSize={1} lineHeight="copy" textAlign="center">
    <b style={{ display: "block", fontWeight: 600 }}>You do not have any DID Configurations.</b>
    Create a DID Config so you can publicly list your DIDs.
  </Text.span>
);

export const NoDIDConfigsText: React.FunctionComponent = () => (
  <Text.span display="block" fontSize={1} lineHeight="copy" textAlign="center">
    <b style={{ display: "block", fontWeight: 600 }}>You do not have any DID Configurations.</b>
  </Text.span>
);

export const NoIssuedCredentialsText: React.FunctionComponent = () => (
  <Text.span display="block" fontSize={1} lineHeight="copy" textAlign="center">
    <b style={{ display: "block", fontWeight: 600 }}>You have not issued any Verifiable Credentials yet.</b>
    To issue a credential, make sure you have a schema to use in mind. If no schema exists, please create one first.
  </Text.span>
);

export const NoReceivedCredentialsText: React.FunctionComponent = () => (
  <Text.span display="block" fontSize={1} lineHeight="copy" textAlign="center">
    <b style={{ display: "block", fontWeight: 600 }}>You have not received any Verifiable Credentials yet.</b>
  </Text.span>
);

export const NoDIDCredentialsText: React.FunctionComponent = () => (
  <Text.span display="block" fontSize={1} lineHeight="copy" textAlign="center">
    <b style={{ display: "block", fontWeight: 600 }}>You have not created a DID yet.</b>
    To issue a credential, you need to have a DID. You can create one in{" "}
    <Link href={routes.IDENTIFIERS_MY_DIDS}>My DIDs</Link>.
  </Text.span>
);
