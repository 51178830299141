import { useContext, useState } from "react";
import styled from "styled-components";
import useSWR, { mutate } from "swr";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { Box, Button, Flash, Flex } from "rimble-ui";
import { H3, H6, Identifier, SertoUiContext } from "serto-ui";
import { DidConfigDownloadBtn, DidViewAlias, ErrorMsgDidConfig } from "../../components";

const StyledWrap = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &:last-of-type {
    border-bottom: none;
  }
`;

export interface DidConfigEditProps {
  domain: string;
  configIdentifiers: any;
  hasPermissions: boolean;
  onComplete(): void;
}

export const DidConfigEdit: React.FunctionComponent<DidConfigEditProps> = (props) => {
  const { configIdentifiers, hasPermissions } = props;
  const { toastProvider } = useContext(SertoUiContext);
  const SertoAgent = useContext(SertoAgentContext);
  const { data: orgIdentifiers } = useSWR("/v1/agent/didManagerFind", () => SertoAgent.getDids(), {
    revalidateOnFocus: false,
  });
  let selectIdentifiers: Identifier[] = [];
  if (orgIdentifiers) {
    selectIdentifiers = orgIdentifiers.filter(
      ({ did: orgDid }) => !configIdentifiers.some(({ did: configDid }: any) => orgDid === configDid),
    );
  }
  const [error, setError] = useState<any | undefined>();

  async function deleteDidConfig() {
    try {
      await SertoAgent.deleteDidConfig(props.domain);
      mutate(SertoAgent.api.DID_CONFIG);
      toastProvider.addMessage(`${props.domain} DID config has been deleted`, {
        colorTheme: "light",
        variant: "success",
      });
      props.onComplete();
      return;
    } catch (err) {
      console.error("failed to delete did config:", err);
      setError(<ErrorMsgDidConfig error={err.message} />);
      return;
    }
  }

  async function deleteDidfromConfig(did: string) {
    try {
      await SertoAgent.deleteDidfromConfig(props.domain, did);
      mutate(SertoAgent.api.DID_CONFIG);
      mutate(`${SertoAgent.api.DID_CONFIG}${props.domain}`);
      toastProvider.addMessage(`DID removed from ${props.domain}`, {
        colorTheme: "light",
        variant: "success",
      });
      props.onComplete();
      return;
    } catch (err) {
      console.error("failed to delete did from config:", err);
      setError(<ErrorMsgDidConfig error={err.message} />);
      return;
    }
  }

  async function addDidToConfig(did: string) {
    try {
      await SertoAgent.addDidToConfig(props.domain, did);
      mutate(SertoAgent.api.DID_CONFIG);
      mutate(`${SertoAgent.api.DID_CONFIG}${props.domain}`);
      toastProvider.addMessage(`DID added to ${props.domain}`, {
        colorTheme: "light",
        variant: "success",
      });
      props.onComplete();
      return;
    } catch (err) {
      console.error("failed to add did to config:", err);
      setError(<ErrorMsgDidConfig error={err.message} />);
      return;
    }
  }

  return (
    <Box>
      <H3 mb={4} mt={0}>
        {props.domain}
      </H3>
      <Box mb={5}>
        <H6 mb={2} mt={0}>
          Linked DIDs:
        </H6>
        <Box border={2} maxHeight="170px" overflow="scroll">
          {configIdentifiers.map((did: any, i: number) => {
            return (
              <StyledWrap borderBottom={2} key={i} padding={3}>
                <Box width="calc(100% - 125px)">
                  <DidViewAlias alias={did.alias} did={did.did} inline />
                </Box>
                {hasPermissions && (
                  <Button.Outline
                    onClick={() => deleteDidfromConfig(did.did)}
                    size="small"
                    title="Remove DID from DID Config"
                  >
                    Remove
                  </Button.Outline>
                )}
              </StyledWrap>
            );
          })}
        </Box>
      </Box>
      {hasPermissions && selectIdentifiers?.length > 0 && (
        <Box mb={5}>
          <H6 mb={1} mt={0}>
            Add DIDs:
          </H6>
          <Box border={2} maxHeight="170px" overflow="scroll">
            {selectIdentifiers?.map((identifier: any, i: number) => {
              return (
                <StyledWrap borderBottom={2} key={i} padding={3}>
                  <Box width="calc(100% - 110px)">
                    <DidViewAlias alias={identifier.alias} did={identifier.did} inline />
                  </Box>
                  <Button.Outline
                    onClick={() => addDidToConfig(identifier.did)}
                    size="small"
                    title="Add DID to DID Config"
                  >
                    Add
                  </Button.Outline>
                </StyledWrap>
              );
            })}
          </Box>
        </Box>
      )}
      {error && (
        <Flash mb={3} variant="danger">
          {error}
        </Flash>
      )}
      <DidConfigDownloadBtn btnText="Download DID Configuration" domain={props.domain} width="100%" />
      {hasPermissions && (
        <Button.Outline
          onClick={() => deleteDidConfig()}
          mt={3}
          title="Delete DID Config"
          variant="danger"
          width="100%"
        >
          Delete DID Configuration
        </Button.Outline>
      )}
    </Box>
  );
};
