import { useContext, useState } from "react";
import { mutate } from "swr";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { UserUpdateTypes } from "../../types";
import { Box, Button, Field, Flash, Form, Input, Loader } from "rimble-ui";
import { baseColors, ModalWithX, SertoUiContext } from "serto-ui";
import { ErrorMsgMemberManagement, FormField, FormLabel, FormHelperText, RequireFieldText } from "../../components";
import { ProfileResetPassword } from "./ProfileResetPassword";

export interface ProfileEditProps {
  user: UserUpdateTypes;
}

export const ProfileEdit: React.FunctionComponent<ProfileEditProps> = (props) => {
  const SertoAgent = useContext(SertoAgentContext);
  const { toastProvider } = useContext(SertoUiContext);
  const [error, setError] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isResetModalOpen, setIsResetModalOpen] = useState<boolean>(false);
  const [user, setUser] = useState<UserUpdateTypes>({
    id: props.user.id,
    name: props.user.name,
    username: props.user.username,
    role: props.user.role,
  });

  const updateUser = async (e: Event) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    if (!user.name || !user.username || !user.role) {
      setError("All fields are required.");
    } else if (user.username.length < 8) {
      setError("Minimum 8 characters required for username.");
    } else {
      try {
        await SertoAgent.updateUser(user);
        mutate(SertoAgent.api.USERS);
        toastProvider.addMessage("Profile successfully updated", {
          colorTheme: "light",
          variant: "success",
        });
      } catch (err) {
        console.error("failed to update user:", err);
        setError(<ErrorMsgMemberManagement error={err.message} />);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Form mb={5} onSubmit={updateUser} width="425px">
        <RequireFieldText />
        <Field label="Name *" width="100%">
          <Input
            onChange={(e: any) => setUser((inputs) => ({ ...inputs, name: e.target.value }))}
            placeholder="Full Name"
            required
            type="text"
            value={user.name}
            width="100%"
          />
        </Field>
        <FormField>
          <FormLabel>Email *</FormLabel>
          <Input
            disabled
            onChange={(e: any) => setUser((inputs) => ({ ...inputs, username: e.target.value }))}
            placeholder="Email"
            required
            type="text"
            value={user.username}
            width="100%"
          />
          <FormHelperText>DID and credentials-related notifications will be sent to this email</FormHelperText>
        </FormField>
        {error && (
          <Flash mb={3} variant="danger">
            {error}
          </Flash>
        )}
        <Button disable={loading} type="submit" width="100%">
          {loading ? <Loader color={baseColors.white} /> : "Update Profile"}
        </Button>
      </Form>
      <Box width="425px">
        <FormField>
          <FormLabel>Password</FormLabel>
          <Input disabled placeholder="************" width="100%" />
        </FormField>
        <Button.Outline onClick={() => setIsResetModalOpen(true)} size="small">
          Reset Password
        </Button.Outline>
      </Box>
      <ModalWithX isOpen={isResetModalOpen} close={() => setIsResetModalOpen(false)} borderRadius={2} width="425px">
        <ProfileResetPassword close={() => setIsResetModalOpen(false)} />
      </ModalWithX>
    </>
  );
};
