import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Box, Button, Field, Form, Input, Flash, Loader } from "rimble-ui";
import { baseColors } from "serto-ui";
import { OnboardingWidget, OnboardingWidgetDescription } from "../../../components";
import { config } from "../../../config";
import { urlRegex } from "../../../constants";
import { SertoAgentContext } from "../../../context/SertoAgentProvider";

export interface OrgProfileProps {
  did: any;
  onboardingFlow: string;
  onComplete(vc: any, domain: string): void;
}

export const OrgProfile: React.FunctionComponent<OrgProfileProps> = (props) => {
  const SertoAgent = useContext(SertoAgentContext);
  const [name, setName] = useState<string>("");
  const [domain, setDomain] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [logoUrl, setLogoUrl] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  async function handleSubmit(e: any) {
    e.preventDefault();
    setLoading(true);
    const did = typeof props.did === "string" ? props.did : props.did.did;
    const getUri = config.SEARCH_API_URL + "/v1/org-profile-schema-prefix";
    const orgProfileSchema = (await axios.get(getUri)).data;
    const credential = {
      issuer: { id: did },
      "@context": ["https://www.w3.org/2018/credentials/v1", orgProfileSchema + "ld-context.json"],
      type: ["VerifiableCredential", "SertoOrganizationProfile"],
      credentialSchema: {
        id: orgProfileSchema + "json-schema.json",
        type: "JsonSchemaValidator2018",
      },
      credentialSubject: {
        domain,
        name,
        description,
        logoUrl,
      },
    };

    try {
      const vc = await SertoAgent.issueVc(credential);
      setLoading(false);
      props.onComplete(vc, domain);
    } catch (err) {
      console.error("failed to create org vc:", err);
      return;
    }
  }

  useEffect(() => {
    setDisabled(!name || !domain || !description);
  }, [name, domain, description]);

  return (
    <OnboardingWidget
      onboardingFlow={props.onboardingFlow}
      step={2}
      stepTitleNumber={2}
      title="Issue organization profile Verifiable Credential (VC)"
    >
      <OnboardingWidgetDescription>
        This information will be stored as a verifiable credential (VC). It can be made public and searchable in Serto
        Search. Don’t worry, you can edit this information later.
      </OnboardingWidgetDescription>
      <Form maxWidth="424px" onSubmit={(e: any) => handleSubmit(e)}>
        <Box mb={5}>
          <Field label="Organization's Domain" width="100%">
            <Input
              required
              type="text"
              placeholder="Ex. yourwebsite.com"
              value={domain}
              onChange={(e: any) => {
                setDomain(e.target.value);
              }}
              width="100%"
            />
          </Field>
          <Field label="Organization Name" width="100%">
            <Input
              required
              type="text"
              placeholder="Ex. Serto"
              value={name}
              onChange={(e: any) => {
                setName(e.target.value);
              }}
              width="100%"
            />
          </Field>
          <Field label="Description" width="100%">
            <Input
              required
              type="text"
              placeholder="Describe your organization"
              value={description}
              onChange={(e: any) => {
                setDescription(e.target.value);
              }}
              width="100%"
            />
          </Field>
          <Field label="Logo URL" width="100%">
            <Input
              type="text"
              placeholder="Ex. https://yourwebsite.com/img/logo.png"
              value={logoUrl}
              onBlur={() => (urlRegex.test(logoUrl) || !logoUrl ? setError(false) : setError(true))}
              onChange={(e: any) => {
                setLogoUrl(e.target.value);
                if (urlRegex.test(logoUrl)) {
                  setError(false);
                }
              }}
              width="100%"
            />
          </Field>
          {error && (
            <Flash mb={3} variant="danger">
              Not a valid URL.
            </Flash>
          )}
        </Box>
        <Button disabled={disabled || loading} type="submit" width="100%">
          {loading ? <Loader color={baseColors.white} /> : "Issue Credential"}
        </Button>
      </Form>
    </OnboardingWidget>
  );
};
