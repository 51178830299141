import { useContext } from "react";
import useSWR from "swr";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { Button } from "rimble-ui";

export interface DidConfigDownloadProps {
  btnText?: string;
  btnType?: JSX.Element;
  domain: string;
  size?: string;
  width?: string;
}

export const DidConfigDownloadBtn: React.FunctionComponent<DidConfigDownloadProps> = (props) => {
  const SertoAgent = useContext(SertoAgentContext);
  const { data } = useSWR(`${SertoAgent.api.DID_CONFIG}${props.domain}`, () => SertoAgent.getDidConfig(props.domain), {
    revalidateOnFocus: false,
  });
  const btnText = <>{props.btnText || "Download DID Config File"}</>;

  const ButtonComponent = props.btnType ? props.btnType : Button;

  if (data) {
    const didConfig = "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));
    return (
      <ButtonComponent
        as="a"
        href={"data:" + didConfig}
        download="did-configuration.json"
        size={props.size || "medium"}
        title="Download DID Config File"
        width={props.width}
      >
        {btnText}
      </ButtonComponent>
    );
  }

  return (
    <ButtonComponent disabled size={props.size || "medium"} title="Download DID Configuration" width={props.width}>
      {btnText}
    </ButtonComponent>
  );
};
