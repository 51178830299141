import { routes, sections } from "../../constants";
import { Domains } from "./Domains";
import { Header, HeaderBox } from "serto-ui";
import { ContentBox, Global } from "../../components";

export const DomainsPage: React.FunctionComponent = () => {
  return (
    <Global section={sections.ORGANIZATION} url={routes.ORGANIZATION_DOMAIN}>
      <HeaderBox>
        <Header heading="Organization" />
      </HeaderBox>
      <ContentBox>
        <Domains />
      </ContentBox>
    </Global>
  );
};
