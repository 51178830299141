import { useContext, useState, Fragment } from "react";
import useSWR from "swr";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { AddCircle } from "@rimble/icons";
import { Box, Button, Table } from "rimble-ui";
import { colors, Identifier, ModalWithX, TBody, TH, THead, TR } from "serto-ui";
import {
  DIDConfigRequiresDIDText,
  DidConfigStatusMinimal,
  IsError,
  IsValidating,
  NoDIDConfigsText,
  NoDIDConfigsAdminText,
  NoData,
  SecondaryHeader,
} from "../../components";
import { DidConfigCreate } from "./DidConfigCreate";
import { DidConfigEdit } from "./DidConfigEdit";

export const Domains: React.FunctionComponent = () => {
  const SertoAgent = useContext(SertoAgentContext);
  const hasPermissions = SertoAgent.getPermissions() === "admin";
  const { data: identifiers, isValidating: isValidatingIdentifiers } = useSWR(
    SertoAgent.api.VERAMO_GET_DIDS,
    () => SertoAgent.getDids(),
    {
      revalidateOnFocus: false,
    },
  );
  const { data: domains, isValidating: isValidatingDomains, error: errorDomains } = useSWR(
    SertoAgent.api.DID_CONFIG,
    () => SertoAgent.getAllDidConfigs(),
    {
      revalidateOnFocus: false,
    },
  );
  const [isDidConfigModalOpen, setIsDidConfigModalOpen] = useState<boolean>(false);
  const [isDidConfigEditModalOpen, setIsDidConfigEditModalOpen] = useState<boolean>(false);
  const [selectedDomain, setSelectedDomain] = useState<string>("");
  const [selectedDids, setSelectedDids] = useState<Identifier>();

  return (
    <>
      <SecondaryHeader
        heading="Domains"
        cta={
          <>
            {hasPermissions && identifiers && identifiers.length > 0 && (
              <Button onClick={() => setIsDidConfigModalOpen(true)} size="small">
                <AddCircle size="14px" mr={1} color={colors.primary.disabled[1]} />
                Create DID Configuration
              </Button>
            )}
          </>
        }
      />
      {isValidatingIdentifiers || isValidatingDomains ? (
        <IsValidating />
      ) : errorDomains ? (
        <IsError error={"Error loading api keys: " + errorDomains.toString()} />
      ) : domains && Object.keys(domains).length > 0 ? (
        <Table border={0} boxShadow={0} width="100%">
          <THead>
            <TR>
              <TH>Domain</TH>
              <TH>Attached DIDs</TH>
              <TH>Status</TH>
              <TH />
            </TR>
          </THead>
          <TBody>
            {domains.map((domain: any, i: number) => {
              const domainName = domain.domain;
              const identifiers = domain.identifiers;
              if (domain.dids.length === 0) {
                return <Fragment key={i}></Fragment>;
              }
              return (
                <TR key={i}>
                  <td style={{ verticalAlign: "top" }}>
                    <b>{domainName}</b>
                  </td>
                  <td style={{ verticalAlign: "top" }}>{identifiers.length}</td>
                  <td style={{ verticalAlign: "top" }}>
                    <DidConfigStatusMinimal domain={domainName} status={domain.status} />
                  </td>
                  <td style={{ verticalAlign: "top" }} width="175px">
                    <Button.Outline
                      onClick={() => {
                        setSelectedDomain(domainName);
                        setSelectedDids(identifiers);
                        setIsDidConfigEditModalOpen(true);
                      }}
                      mb={2}
                      size="small"
                      width="100%"
                    >
                      {hasPermissions ? "View/Edit" : "View"}
                    </Button.Outline>
                  </td>
                </TR>
              );
            })}
          </TBody>
        </Table>
      ) : (
        <>
          {hasPermissions ? (
            <>
              {identifiers && identifiers.length > 0 ? (
                <NoData text={<NoDIDConfigsAdminText />} />
              ) : (
                <NoData text={<DIDConfigRequiresDIDText />} />
              )}
            </>
          ) : (
            <>
              {identifiers && identifiers.length > 0 ? (
                <NoData text={<NoDIDConfigsText />} />
              ) : (
                <NoData text={<DIDConfigRequiresDIDText />} />
              )}
            </>
          )}
        </>
      )}

      <ModalWithX
        isOpen={isDidConfigModalOpen}
        close={() => setIsDidConfigModalOpen(false)}
        borderRadius={2}
        width="600px"
      >
        <Box px={4} style={{ overflow: "scroll" }}>
          <DidConfigCreate close={() => setIsDidConfigModalOpen(false)} />
        </Box>
      </ModalWithX>

      <ModalWithX
        isOpen={isDidConfigEditModalOpen}
        close={() => setIsDidConfigEditModalOpen(false)}
        borderRadius={2}
        width="600px"
      >
        <Box px={4} style={{ overflow: "scroll" }}>
          <DidConfigEdit
            domain={selectedDomain}
            configIdentifiers={selectedDids}
            hasPermissions={hasPermissions}
            onComplete={() => setIsDidConfigEditModalOpen(false)}
          />
        </Box>
      </ModalWithX>
    </>
  );
};
