export interface ErrorProps {
  error: string;
}

// Login and Signup
export const ErrorMsgAuth: React.FunctionComponent<ErrorProps> = (props) => {
  const { error } = props;
  if (error.includes("312")) {
    return <>User does not exist.</>;
  }
  if (error.includes("318")) {
    return <>Incorrect password.</>;
  }
  return <>Error logging in, please try again. If this error persists contact your admin.</>;
};

export const ErrorMsgSignup: React.FunctionComponent<ErrorProps> = (props) => {
  const { error } = props;
  if (error.includes("455")) {
    return <>A user already exists with that username.</>;
  }
  return <>Error signing up, please try again. If this error persists contact your admin.</>;
};

// Config Setup
export const ErrorMsgSetup: React.FunctionComponent<ErrorProps> = (props) => {
  const { error } = props;
  if (error.includes("310")) {
    return <>Serto Agent has already been configured once.</>;
  } else if (error.includes("311")) {
    return <>Fail to save Serto Agent configuration during setup.</>;
  }
  return <>{error}</>;
};

// DID Configuration
export const ErrorMsgDidConfig: React.FunctionComponent<ErrorProps> = (props) => {
  const { error } = props;
  if (error.includes("108")) {
    return <>The provided DID is not valid.</>;
  } else if (error.includes("109")) {
    return <>At least one DID is required for the DID configuration.</>;
  }
  return <>{error}</>;
};

// Identifiers
export const ErrorMsgIdentifiers: React.FunctionComponent<ErrorProps> = (props) => {
  const { error } = props;
  if (error.includes("317")) {
    return <>A DID with that alias already exists. Please choose a new alias.</>;
  }
  return <>{error}</>;
};
export const ErrorMsgIdentifiersInvalidDomain: React.FunctionComponent = () => {
  return <>Invalid domain: domain should be for example "domain.com" and should not include the protocol.</>;
};

// Member Management
export const ErrorMsgMemberManagement: React.FunctionComponent<ErrorProps> = (props) => {
  const { error } = props;
  if (error.includes("312")) {
    return <>User name must be unique.</>;
  } else if (error.includes("455")) {
    return <>User not found.</>;
  }
  return <>{error}</>;
};

// Search
export const ErrorMsgSearch: React.FunctionComponent<ErrorProps> = (props) => {
  const { error } = props;
  if (error.includes("211")) {
    return <>Requested search entry not found.</>;
  } else if (error.includes("215")) {
    return <>Invalid Search Domain.</>;
  }
  return <>{error}</>;
};

// Settings
export const ErrorMsgSettings: React.FunctionComponent<ErrorProps> = (props) => {
  const { error } = props;
  if (error.includes("117")) {
    return <>Missing API Key name.</>;
  } else if (error.includes("118")) {
    return <>API Key with this name already exists.</>;
  } else if (error.includes("311")) {
    return <>Fail to save email configuration.</>;
  }
  return <>{error}</>;
};
