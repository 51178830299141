import { useContext, useState } from "react";
import useSWR from "swr";
import { SertoAgentContext } from "../../../context/SertoAgentProvider";
import { Box } from "rimble-ui";
import { Credential, Tabs } from "serto-ui";
import { DidViewAlias, IsValidating, NoDIDCredentialsText, NoData, NoIssuedCredentialsText } from "../../../components";

export const DashboardActivityFeed: React.FunctionComponent = () => {
  const [tabName, setTabName] = useState<string>("credentials");

  return (
    <Tabs
      activeTabName={tabName}
      tabs={[
        {
          tabName: "credentials",
          title: "Credentials",
          content: <CredentialsFeed />,
        },
        {
          tabName: "identifiers",
          title: "Identifiers",
          content: <IdentifiersFeed />,
        },
      ]}
      onTabClicked={(newTabName) => setTabName(newTabName)}
    />
  );
};

export const CredentialsFeed: React.FunctionComponent = () => {
  const SertoAgent = useContext(SertoAgentContext);
  const { data: credentials, isValidating } = useSWR(
    SertoAgent.api.VERAMO_GET_CREDENTIALS,
    () => SertoAgent.getCredentials(),
    {
      revalidateOnFocus: false,
    },
  );

  return (
    <Box minHeight="450px" p={4}>
      {isValidating ? (
        <IsValidating />
      ) : credentials && credentials.length > 0 ? (
        <>
          {credentials
            .reverse()
            .slice(0, 5)
            .map((vc: any, i: number) => (
              <Box borderBottom={2} key={i} mb={3} pb={3}>
                <Credential vc={vc.verifiableCredential} />
              </Box>
            ))}
        </>
      ) : (
        <NoData text={<NoIssuedCredentialsText />} />
      )}
    </Box>
  );
};

export const IdentifiersFeed: React.FunctionComponent = () => {
  const SertoAgent = useContext(SertoAgentContext);
  const { data: identifiers, isValidating } = useSWR(SertoAgent.api.VERAMO_GET_DIDS, () => SertoAgent.getDids(), {
    revalidateOnFocus: false,
  });

  return (
    <Box minHeight="450px" p={4}>
      {isValidating ? (
        <IsValidating />
      ) : identifiers && identifiers.length > 0 ? (
        <>
          {identifiers
            .reverse()
            .slice(0, 5)
            .map((identifier: any, i: number) => {
              return (
                <Box borderBottom={2} key={i} mb={3} pb={3}>
                  <DidViewAlias alias={identifier.alias} copy did={identifier.did} />
                </Box>
              );
            })}
        </>
      ) : (
        <NoData text={<NoDIDCredentialsText />} />
      )}
    </Box>
  );
};
