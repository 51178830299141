import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { routes, sections } from "../../constants";
import { Members } from "./Members";
import { Header, HeaderBox } from "serto-ui";
import { ContentBox, Global } from "../../components";

export const MembersPage: React.FunctionComponent = () => {
  const SertoAgent = useContext(SertoAgentContext);
  const hasPermissions = SertoAgent.getPermissions() === "admin";
  const history = useHistory();

  if (!hasPermissions) {
    history.push(routes.ORGANIZATION_DOMAIN);
  }

  return (
    <Global section={sections.ORGANIZATION} url={routes.ORGANIZATION_MEMBERS}>
      <HeaderBox>
        <Header heading="Organization" />
      </HeaderBox>
      <ContentBox>
        <Members />
      </ContentBox>
    </Global>
  );
};
