import { useContext } from "react";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { Text } from "rimble-ui";
import { baseColors, GlobalLayout } from "serto-ui";
import { OrgHeader } from "../elements/OrgHeader";
import { LogOut } from "../../views/Auth/LogOut";

import { config } from "../../config";
import { routes, sections } from "../../constants";
import { SertoAgentProvider } from "../../context/SertoAgentProvider";
import { SertoUiWrapper } from "../../context/SertoUiWrapper";
import { AssignmentInd, Business, CardMembership, Home, Info, Settings } from "@rimble/icons";

export interface GlobalProps {
  section: string;
  url: string;
}

const navItems = [
  { text: "Home", section: sections.HOMEPAGE, url: routes.HOMEPAGE, icon: Home },
  {
    text: "Credentials",
    section: sections.CREDENTIALS,
    url: routes.CREDENTIALS,
    icon: CardMembership,
    subNav: [
      { text: "Issued Credentials", url: routes.CREDENTIALS_ISSUED },
      { text: "Received Credentials", url: routes.CREDENTIALS_RECEIVED },
    ],
  },
  {
    text: "Identifiers",
    section: sections.IDENTIFIERS,
    url: routes.IDENTIFIERS,
    icon: AssignmentInd,
    subNav: [
      { text: "My DIDs", url: routes.IDENTIFIERS_MY_DIDS },
      { text: "Search", url: routes.IDENTIFIERS_SEARCH },
    ],
  },
  {
    text: "Organization",
    section: sections.ORGANIZATION,
    url: routes.ORGANIZATION,
    icon: Business,
    subNav: [
      { text: "Domains", url: routes.ORGANIZATION_DOMAIN },
      { text: "Members", url: routes.ORGANIZATION_MEMBERS, admin: true },
    ],
  },
  {
    text: "Settings",
    section: sections.SETTINGS,
    url: routes.SETTINGS,
    icon: Settings,
    subNav: [
      { text: "My Profile", url: routes.SETTINGS_PROFILE },
      { text: "API Keys", url: routes.SETTINGS_API, admin: true },
      { text: "DID Methods", url: routes.SETTINGS_DID_METHODS, admin: true },
      { text: "Email Server", url: routes.SETTINGS_EMAIL, admin: true },
      { text: "Database Setup", url: routes.SETTINGS_DATABASE, admin: true },
    ],
  },
];
const featureFlags = config.FEATURE_FLAGS ? config.FEATURE_FLAGS.split(",") : [];

export const Global: React.FunctionComponent<GlobalProps> = (props) => {
  const SertoAgent = useContext(SertoAgentContext);
  const isAdmin = SertoAgent.getPermissions() === "admin";

  return (
    <SertoAgentProvider featureFlags={featureFlags}>
      <SertoUiWrapper navItems={navItems}>
        <GlobalLayout
          url={props.url}
          banner={{
            content: (
              <>
                <Info color={baseColors.white} mr={1} />
                <Text color={baseColors.white} fontSize={[0, 2]}>
                  Serto Agent is in beta.
                </Text>
              </>
            ),
          }}
          hasPermissions={isAdmin}
          section={props.section}
          sidebarBottomContents={<LogOut />}
          sidebarTopContents={<OrgHeader />}
        >
          {props.children}
        </GlobalLayout>
      </SertoUiWrapper>
    </SertoAgentProvider>
  );
};
