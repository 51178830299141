import styled from "styled-components";
import { CheckCircle } from "@rimble/icons";
import { Box, Button, Flash, Flex, Loader } from "rimble-ui";
import { baseColors, colors, GreenCircleCheck, H3 } from "serto-ui";

export interface ContentBoxProps {
  [key: string]: any;
}
export const ContentBox: React.FunctionComponent<ContentBoxProps> = (props) => {
  return (
    <Box bg={baseColors.white} borderRadius={1} flexGrow="1" {...props}>
      {props.children}
    </Box>
  );
};

export interface IsErrorProps {
  error: string | JSX.Element;
}
export const IsError: React.FunctionComponent<IsErrorProps> = (props) => {
  return (
    <Box px={3} my={3}>
      <Flash variant="danger">{props.error}</Flash>
    </Box>
  );
};

export interface IsSuccessProps {
  btnText?: string;
  loader?: boolean;
  text: string;
  onClick?(): void;
}
export const IsSuccess: React.FunctionComponent<IsSuccessProps> = (props) => {
  return (
    <Box textAlign="center">
      <GreenCircleCheck size="58px" />
      <H3 color={colors.success.base} mb={3} mt={1} textAlign="center">
        {props.text}
      </H3>
      {props.children && <Box mb={3}>{props.children}</Box>}
      {props.onClick && (
        <Button onClick={props.onClick} mb={3} minWidth="200px">
          {props.btnText || "Done"}
        </Button>
      )}
      {props.loader && (
        <Flex justifyContent="center">
          <Loader size="64px" />
        </Flex>
      )}
    </Box>
  );
};

export interface IsSuccessInlineProps {
  text: string;
}
export const IsSuccessInline: React.FunctionComponent<IsSuccessInlineProps> = (props) => {
  return (
    <Flex alignItems="center">
      <CheckCircle color={colors.success.base} mr={2} /> {props.text}
    </Flex>
  );
};

export const IsValidating: React.FunctionComponent = () => {
  return (
    <Box px={3} my={3}>
      <Flex minHeight={8} alignItems="center" justifyContent="center">
        <Loader color={colors.primary.base} size={5} />
      </Flex>
    </Box>
  );
};

export interface NoDataProps {
  text: string | JSX.Element;
  btnText?: string;
  onClick?(): void;
}
export const NoData: React.FunctionComponent<NoDataProps> = (props) => {
  return (
    <Flex alignItems="center" justifyContent="center" minHeight={8}>
      <Box maxWidth={9}>
        {props.text}
        {props.btnText && (
          <Flex alignItems="center" justifyContent="center">
            <Button onClick={props.onClick} size="small" mt={3} mx="auto">
              {props.btnText}
            </Button>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

export const TDCredentials = styled.td`
  font-size: 16px;
  padding: 30px 16px !important;
`;

export const TBodyCredentials = styled.tbody`
  ${TDCredentials}:first-of-type {
    padding: 30px 16px 30px 24px !important;
  }

  ${TDCredentials}:last-of-type {
    padding: 30px 24px 30px 16px !important;
  }
`;
