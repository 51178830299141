import { Header, HeaderBox } from "serto-ui";
import { ContentBox, Global } from "../../components";
import { routes, sections } from "../../constants";
import { ReceivedCredentials } from "./ReceivedCredentials";

export const ReceivedCredentialsPage: React.FunctionComponent = () => {
  return (
    <Global section={sections.CREDENTIALS} url={routes.CREDENTIALS_RECEIVED}>
      <HeaderBox>
        <Header heading="Credentials" />
      </HeaderBox>
      <ContentBox>
        <ReceivedCredentials />
      </ContentBox>
    </Global>
  );
};
