import { useContext } from "react";
import useSWR from "swr";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { Box } from "rimble-ui";
import { ErrorMsgMemberManagement, IsError, IsValidating, NoData, SecondaryHeader } from "../../components";
import { ProfileEdit } from "./ProfileEdit";

export const Profile: React.FunctionComponent = () => {
  const SertoAgent = useContext(SertoAgentContext);
  const { data: user, error, isValidating } = useSWR(SertoAgent.api.ADMIN_USER, () => SertoAgent.getCurrentUser(), {
    revalidateOnFocus: false,
  });

  return (
    <>
      <SecondaryHeader heading="My Profile" />
      <Box borderTop={2} p={4}>
        {isValidating ? (
          <IsValidating />
        ) : error ? (
          <IsError error={<ErrorMsgMemberManagement error={error.toString()} />} />
        ) : user ? (
          <ProfileEdit user={user} />
        ) : (
          <NoData text="User not found" />
        )}
      </Box>
    </>
  );
};
