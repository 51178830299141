import { useContext } from "react";
import useSWR, { mutate } from "swr";
import { routes, sections } from "../../constants";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { Header, HeaderBox, IssueCredentialButton } from "serto-ui";
import { ContentBox, Global } from "../../components";
import { IssuedCredentials } from "./IssuedCredentials";

export const IssuedCredentialsPage: React.FunctionComponent = () => {
  const SertoAgent = useContext(SertoAgentContext);
  const { data: identifiers } = useSWR(SertoAgent.api.VERAMO_GET_DIDS, () => SertoAgent.getDids(), {
    revalidateOnFocus: false,
  });

  return (
    <Global section={sections.CREDENTIALS} url={routes.CREDENTIALS_ISSUED}>
      <HeaderBox>
        <Header heading="Credentials">
          {identifiers && identifiers.length > 0 && (
            <IssueCredentialButton
              issuerIdentifiers={identifiers}
              onComplete={() => mutate(SertoAgent.api.VERAMO_GET_CREDENTIALS)}
            />
          )}
        </Header>
      </HeaderBox>
      <ContentBox>
        <IssuedCredentials issued={true} identifiers={(identifiers && identifiers?.length > 0) || false} />
      </ContentBox>
    </Global>
  );
};
