import { useContext } from "react";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { Button } from "rimble-ui";
import { baseColors, colors } from "serto-ui";

export const LogOut: React.FunctionComponent = () => {
  const SertoAgent = useContext(SertoAgentContext);

  function logout(e: Event) {
    e.preventDefault();
    SertoAgent.logout();
  }

  return (
    <Button
      onClick={logout}
      contrastColor={colors.midGray}
      mainColor={baseColors.white}
      size="small"
      mt={3}
      width="100%"
    >
      Log Out
    </Button>
  );
};
