import { Info } from "@rimble/icons";
import { Box, Button, Flex, Flash, Link, Text } from "rimble-ui";
import { baseColors, colors, Credential } from "serto-ui";
import { ONBOARDING_FLOWS, ONBOARDING_STEPS } from "./Onboarding";
import { IsSuccess } from "../../../components";
import { links } from "../../../constants";

export interface OrgProfileSuccessProps {
  onboardingFlow: string;
  vc: any;
  onComplete(nextStep: string): void;
}

export const OrgProfileSuccess: React.FunctionComponent<OrgProfileSuccessProps> = (props) => {
  return (
    <Flex bg={baseColors.white} borderRadius={1} flexGrow="1" justifyContent="center" p={5}>
      <Box mt={6} maxWidth="450px">
        <Box mb={5}>
          <IsSuccess text="Credential Issued & Saved" />
        </Box>
        <Box mb={5}>
          <Credential vc={props.vc} />
        </Box>

        {props.onboardingFlow === ONBOARDING_FLOWS.ETH && (
          <Box textAlign="center">
            <Button onClick={() => props.onComplete(ONBOARDING_STEPS[4])} width="100%">
              Link DID to Domain
            </Button>
          </Box>
        )}

        {props.onboardingFlow === ONBOARDING_FLOWS.CUSTOM_DOMAIN_DNS && (
          <Box textAlign="center">
            <Button onClick={() => props.onComplete(ONBOARDING_STEPS[6])} width="100%">
              Next
            </Button>
          </Box>
        )}

        {props.onboardingFlow === ONBOARDING_FLOWS.GENERIC_DOMAIN && (
          <>
            <Flash variant="info" mb={5}>
              <Flex>
                <Info color={colors.info.dark} />
                <Text ml={2}>
                  Your organization’s DID is not human-readable as the Issuer. To let others find and verify you easier,
                  link your DID to your organization’s web domain,{" "}
                  <Link href={links.DOC_DNS_SETUP} target="_blank">
                    or set up your DNS
                  </Link>
                  .
                </Text>
              </Flex>
            </Flash>
            <Box textAlign="center">
              <Button onClick={() => props.onComplete(ONBOARDING_STEPS[7])} maxWidth="300px" width="100%">
                Next
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Flex>
  );
};
