import { baseColors, colors, H4 } from "serto-ui";
import { Box, Button, Flex } from "rimble-ui";
import { OnboardingTypes } from "../../../types";

export interface OnboardingNoticeProps {
  onboarding: OnboardingTypes;
  startOnboarding(): void;
}

export const OnboardingNotice: React.FunctionComponent<OnboardingNoticeProps> = (props) => {
  const { onboarding } = props;
  const webFlowFinished = onboarding.did?.includes("did:web") && onboarding.orgVc;
  const nonWebFlowFinished = !onboarding.did?.includes("did:web") && onboarding.didConfigDomain;

  if (webFlowFinished || nonWebFlowFinished) {
    return <></>;
  }

  return (
    <Box bg={colors.primary.base} borderRadius={1} mb={3} p={4}>
      <Flex alignItems="center" justifyContent="space-between">
        <H4 color={baseColors.white} m={0}>
          Get your Agent fully set up!
        </H4>
        <Button
          contrastColor={colors.primary.base}
          mainColor={baseColors.white}
          onClick={() => props.startOnboarding()}
        >
          Finish Setup Process
        </Button>
      </Flex>
    </Box>
  );
};
