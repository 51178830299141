import { config as sertoUiConfig, SertoUiConfig, mergeServerConfig } from "serto-ui";

export interface ConfigType extends SertoUiConfig {
  AGENT_API_URL: string;
  AGENT_UI_URL: string;
}

const defaultConfig: ConfigType = {
  ...sertoUiConfig,
  AGENT_API_URL: sertoUiConfig.IS_DEV ? "http://localhost:8083" : window.location.origin,
  AGENT_UI_URL: window.location.origin,
};

export const config = mergeServerConfig(defaultConfig);
