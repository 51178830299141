import { useContext, useState } from "react";
import { mutate } from "swr";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { Button, Field, Flash, Form, Input, Loader, Text } from "rimble-ui";
import { baseColors, CopyableTruncatableText, ModalContent, ModalHeader } from "serto-ui";
import { ErrorMsgSettings } from "../../components";

export interface APIKeyManagementCreateProps {
  close(): void;
}

export const APIKeyManagementCreate: React.FunctionComponent<APIKeyManagementCreateProps> = (props) => {
  const SertoAgent = useContext(SertoAgentContext);
  const [error, setError] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [apiKeyGenerated, setApiKeyGenerated] = useState<boolean>(false);
  const [apiKeyName, setApiKeyName] = useState<string>("");
  const [apiKey, setApiKey] = useState<string>("");

  const createApiKey = async (e: Event) => {
    e.preventDefault();

    if (!apiKeyName) {
      setError("Name is required.");
      return;
    }

    setError("");
    setLoading(true);

    try {
      const resp = await SertoAgent.createApiKey(apiKeyName);
      setApiKeyName("");
      setApiKey(resp.apiKey);
      await mutate(SertoAgent.api.API_KEYS);
      setApiKeyGenerated(true);
    } catch (err) {
      console.error("failed to create api key:", err);
      setError(<ErrorMsgSettings error={err.message} />);
    }

    setLoading(false);
  };

  const confirmReceiptOfApiKey = async () => {
    props.close();
  };

  if (!apiKeyGenerated) {
    return (
      <>
        <ModalHeader>Create API Key</ModalHeader>
        <ModalContent>
          <Form onSubmit={createApiKey}>
            <Field width="100%" label="Name your API key">
              <Input
                type="text"
                width="100%"
                required
                value={apiKeyName}
                onChange={(e: any) => setApiKeyName(e.target.value)}
              />
            </Field>

            {error && (
              <Flash mb={3} variant="danger">
                {error}
              </Flash>
            )}
            <Button disabled={loading} type="submit" width="100%">
              {loading ? <Loader color={baseColors.white} /> : "Generate API Key"}
            </Button>
          </Form>
        </ModalContent>
      </>
    );
  }

  return (
    <>
      <ModalHeader>Create API Key</ModalHeader>
      <ModalContent>
        <Flash my={3} variant="success">
          <Text>
            Your API Key has been generated. Please copy this API Key and save it in a safe place, as you will not be
            able to see it again.
          </Text>
        </Flash>
        <Text fontSize={1} fontWeight={3} mb={2}>
          API Key
        </Text>
        <CopyableTruncatableText text={apiKey} />
        <Button.Outline onClick={confirmReceiptOfApiKey} mt={3} width="100%">
          Done
        </Button.Outline>
      </ModalContent>
    </>
  );
};
