import { useContext, useState } from "react";
import { mutate } from "swr";
import { SertoUiContext } from "serto-ui";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { Button, Flash, Form, Input } from "rimble-ui";
import { FormField, FormHelperText, FormLabel } from "../../components";
import { ErrorMsgSettings } from "../../components/text";
import { SmtpServerTypes } from "../../types";

export interface EmailServerFormProps {
  smtp?: SmtpServerTypes;
}

export const EmailServerForm: React.FunctionComponent<EmailServerFormProps> = (props) => {
  const { toastProvider } = useContext(SertoUiContext);
  const SertoAgent = useContext(SertoAgentContext);
  const [error, setError] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [smtp, setSmtp] = useState<SmtpServerTypes>({
    host: props.smtp?.host || "",
    port: props.smtp?.port || 0,
    username: props.smtp?.username || "",
    password: props.smtp?.password || "",
  });

  async function updateSmtpServer(e: Event) {
    e.preventDefault();
    setError(false);
    setLoading(true);

    try {
      await SertoAgent.updateSmtpServer(smtp);
      mutate(SertoAgent.api.SETTINGS_SMTP);
      toastProvider.addMessage("Email server successfully updated", {
        colorTheme: "light",
        variant: "success",
      });
    } catch (err) {
      console.error("error resetting password:", err);
      setError(<ErrorMsgSettings error={err.message} />);
    }
    setLoading(false);
  }

  return (
    <Form onSubmit={updateSmtpServer} maxWidth="500px">
      <FormField>
        <FormLabel>SMTP Host</FormLabel>
        <Input
          onChange={(e: any) => setSmtp((inputs) => ({ ...inputs, host: e.target.value }))}
          placeholder="smtp.gmail.com"
          required
          type="text"
          width="100%"
          value={smtp.host}
        />
        <FormHelperText>Your mail server</FormHelperText>
      </FormField>
      <FormField>
        <FormLabel>SMTP Port</FormLabel>
        <Input
          onChange={(e: any) => setSmtp((inputs) => ({ ...inputs, port: e.target.value }))}
          placeholder="465"
          required
          type="text"
          width="100%"
          value={smtp.port}
        />
        <FormHelperText>The port to your mail server</FormHelperText>
      </FormField>
      <FormField>
        <FormLabel>SMTP Username</FormLabel>
        <Input
          placeholder="Enter Username"
          type="text"
          width="100%"
          required
          onChange={(e: any) => setSmtp((inputs) => ({ ...inputs, username: e.target.value }))}
          value={smtp.username}
        />
        <FormHelperText>The username to login to your mail server</FormHelperText>
      </FormField>
      <FormField>
        <FormLabel>SMTP Password</FormLabel>
        <Input
          onChange={(e: any) => setSmtp((inputs) => ({ ...inputs, password: e.target.value }))}
          placeholder="Enter SMTP password"
          required
          type="text"
          width="100%"
          value={smtp.password}
        />
        <FormHelperText>The password to login to your mail server</FormHelperText>
      </FormField>
      {error && (
        <Flash mb={3} variant="danger">
          {error}
        </Flash>
      )}
      <Button disabled={loading} type="submit" width="100%">
        Save Settings
      </Button>
    </Form>
  );
};
