import { Info } from "@rimble/icons";
import { Flash, Flex, Text } from "rimble-ui";
import { colors, H5 } from "serto-ui";
import { DidConfigStatusLabel } from "../../constants";

export const DidConfigSuccess: React.FunctionComponent = () => {
  return (
    <>
      <H5 mb={1}>Where do I upload this file?</H5>
      <Text fontSize={1} mb={3}>
        Upload this file to the .well-known path of your website.
      </Text>
      <Text fontSize={1} mb={5}>
        The resulting URL should look like: http://www.yourwebsite.com/.well-known/did-configuration.json
      </Text>
      <H5 mb={1}>How do I upload this file?</H5>
      <Text fontSize={1} mb={3}>
        To upload this file, you will need access to your website source files. If you do not manage your website, you
        will need to contact your website administrator or developer.
      </Text>
    </>
  );
};

export const DidConfigExplainer: React.FunctionComponent = () => {
  return (
    <Flash mb={3} variant="info">
      <Flex>
        <Info color={colors.info.dark} />
        <Text ml={2}>
          <b>You will need this file to be publicly discoverable.</b>
          <br />
          <br />A DID Configuration file contains any number of your decentralized identifiers, along with a
          cryptographic proof that ties those identifiers to your web domain.
        </Text>
      </Flex>
    </Flash>
  );
};

export const DidConfigStatusDescription = (status: string): string => {
  switch (status) {
    case DidConfigStatusLabel.NOT_VERIFIED: {
      return "The DID config status has not been checked yet.";
    }
    case DidConfigStatusLabel.NOT_PUBLISHED: {
      return "The DID config was not found in the .well-known path of the domain.";
    }
    case DidConfigStatusLabel.IN_SYNC: {
      return "The DID config is published and matches the one in the agent.";
    }
    case DidConfigStatusLabel.OUT_OF_SYNC: {
      return "The DID config is published but doesn't match the DID config in the agent.";
    }
    case DidConfigStatusLabel.OUT_DATED: {
      return "The DID config matches the DIDs in the agent but contains additional DIDs, which are not listed in the agent.";
    }
    default: {
      return "No status found.";
    }
  }
};
