import styled from "styled-components";
import { colors } from "serto-ui";

const SelectStyled = styled.select`
  border: 1px solid ${colors.moonGray};
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(50, 50, 63, 0.1);
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  margin: 0;
  outline: none;
  padding: 14px;
  width: 100%;
  &:hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  }
`;

export interface SelectOptionTypes {
  value: string;
  label: string;
}

export interface SelectProps {
  defaultValue?: string;
  name?: string;
  required?: boolean;
  options: SelectOptionTypes[];
  onChange(e: any): void;
}

export const Select: React.FunctionComponent<SelectProps> = (props) => {
  return (
    <SelectStyled onChange={(e: any) => props.onChange(e)} required={props.required} value={props.defaultValue}>
      {props.options.map((option: any, i: number) => {
        return (
          <option key={i} value={option.value}>
            {option.label}
          </option>
        );
      })}
    </SelectStyled>
  );
};
