import { useContext, useState } from "react";
import useSWR from "swr";
import { UserUpdateTypes } from "../../types";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { AddCircle } from "@rimble/icons";
import { Button, Table, Text } from "rimble-ui";
import { colors, ModalWithX, TBody, TH, THead, TR } from "serto-ui";
import { IsError, IsValidating, SecondaryHeader } from "../../components";
import { MemberAdd } from "./MemberAdd";
import { MemberDelete } from "./MemberDelete";
import { MemberEdit } from "./MemberEdit";

export const Members: React.FunctionComponent = () => {
  const SertoAgent = useContext(SertoAgentContext);
  const { data: users, isValidating, error } = useSWR(SertoAgent.api.USERS, () => SertoAgent.getUsers(), {
    revalidateOnFocus: false,
  });
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState<boolean>(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState<boolean>(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState<boolean>(false);
  const [userSelected, setUserSelected] = useState<UserUpdateTypes>({
    id: "",
    name: "",
    username: "",
    role: "",
  });

  return (
    <>
      <SecondaryHeader
        heading="Members"
        cta={
          <Button onClick={() => setIsAddUserModalOpen(true)} size="small">
            <AddCircle size="14px" mr={1} color={colors.primary.disabled[1]} />
            Add Member
          </Button>
        }
      />
      {isValidating ? (
        <IsValidating />
      ) : error ? (
        <IsError error={"Error loading users keys: " + error.toString()} />
      ) : users?.length ? (
        <>
          <Table border={0} boxShadow={0} width="100%">
            <THead>
              <TR>
                <TH>Name</TH>
                <TH>Username</TH>
                <TH>Role</TH>
                <TH />
              </TR>
            </THead>
            <TBody>
              {users.map((user: any, i: number) => {
                return (
                  <TR key={i}>
                    <td>
                      <Text fontWeight={3}>{user.name}</Text>
                    </td>
                    <td>{user.username}</td>
                    <td>{user.role === "admin" ? "Admin" : "User"}</td>
                    <td style={{ textAlign: "right" }}>
                      <>
                        <Button
                          onClick={() => {
                            setUserSelected(user);
                            setIsEditUserModalOpen(true);
                          }}
                          mr={2}
                          size="small"
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={() => {
                            setUserSelected(user);
                            setIsDeleteUserModalOpen(true);
                          }}
                          size="small"
                          variant="warning"
                        >
                          Remove Member
                        </Button>
                      </>
                    </td>
                  </TR>
                );
              })}
            </TBody>
          </Table>
        </>
      ) : (
        <></>
      )}

      <ModalWithX isOpen={isAddUserModalOpen} close={() => setIsAddUserModalOpen(false)} borderRadius={2} width="450px">
        <MemberAdd close={() => setIsAddUserModalOpen(false)} />
      </ModalWithX>

      <ModalWithX
        isOpen={isDeleteUserModalOpen}
        close={() => setIsDeleteUserModalOpen(false)}
        borderRadius={2}
        width="450px"
      >
        <MemberDelete user={userSelected} close={() => setIsDeleteUserModalOpen(false)} />
      </ModalWithX>

      <ModalWithX
        isOpen={isEditUserModalOpen}
        close={() => setIsEditUserModalOpen(false)}
        borderRadius={2}
        width="450px"
      >
        <MemberEdit user={userSelected} close={() => setIsEditUserModalOpen(false)} />
      </ModalWithX>
    </>
  );
};
