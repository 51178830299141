import { Box, Button, Flex, Text } from "rimble-ui";
import { baseColors, colors, H6, Identifier } from "serto-ui";
import { IsSuccess } from "../../../components";

export interface GenerateDIDSuccessProps {
  did: Identifier;
  onboardingFlow: string;
  onComplete(): void;
}

export const GenerateDIDSuccess: React.FunctionComponent<GenerateDIDSuccessProps> = (props) => {
  return (
    <Flex bg={baseColors.white} borderRadius={1} flexGrow="1" justifyContent="center" p={5} pt={6} width="100%">
      <Box maxWidth="450px" textAlign="center">
        <IsSuccess text="Your Identifier (DID) has been created">
          <H6 color={colors.midGray} m={0} textAlign="center" style={{ wordBreak: "break-word" }}>
            {props.did.did}
          </H6>
        </IsSuccess>
        <Text fontSize={2} fontWeight={3} mb={3} mt={6}>
          Next, issue your first credential.
        </Text>
        <Button onClick={() => props.onComplete()} maxWidth="300px" width="100%">
          Issue Organization Credential
        </Button>
      </Box>
    </Flex>
  );
};
