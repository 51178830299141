import { Box, Flex } from "rimble-ui";
import { baseColors, SertoAgentLogo } from "serto-ui";
import { Viewport } from "../elements";

export const MinimalLayout: React.FunctionComponent = (props) => {
  return (
    <Box bg={baseColors.white} minHeight="100vh" position="relative" pb={5} pt="72px">
      <Box bg={baseColors.white} boxShadow={1} position="fixed" top="0" width="100%" zIndex="10">
        <Viewport>
          <Flex alignItems="center" height="72px">
            <SertoAgentLogo />
          </Flex>
        </Viewport>
      </Box>
      <Viewport>{props.children}</Viewport>
    </Box>
  );
};
