import { useContext } from "react";
import useSWR from "swr";
import { useHistory } from "react-router-dom";
import { SertoSearchContext } from "../../../context/SertoAgentProvider";
import { Box, Flash, Text } from "rimble-ui";
import { baseColors, colors, SearchBox } from "serto-ui";
import { SearchResult, SearchResultTypes } from "./SearchResult";
import { IsError, IsValidating } from "../../../components";

export const Search: React.FunctionComponent = () => {
  const history = useHistory();
  const Search = useContext(SertoSearchContext);
  const urlParams = new URLSearchParams(window.location.search);
  const filter = urlParams.get("filter") || "";
  const { data, error, isValidating } = useSWR([Search.api.SEARCH, filter], () => Search.getEntries(filter), {
    revalidateOnFocus: false,
  });

  if (error) {
    console.error(error);
  }

  return (
    <>
      <Box bg={baseColors.white} borderRadius={2}>
        <Box maxWidth="500px" p={4} pb={0}>
          <SearchBox
            onSearch={(search: any) => history.push("?filter=" + search)}
            placeholderText="Search for an organization or DID"
          />
        </Box>
        {data?.length > 0 ? (
          <Box bg={baseColors.white} borderRadius={1} py={3}>
            <Box borderBottom={4} pb={1} pt={5} px={5}>
              <Text color={colors.lightSilver} fontSize={2} fontWeight={3}>
                {filter === "" ? (
                  <>
                    Showing {data.length} {data.length === 1 ? "result" : "results"}
                  </>
                ) : (
                  <>
                    Showing {data.length} {data.length === 1 ? "result" : "results"} for{" "}
                    <Text.span color={colors.midGray} fontWeight={3}>
                      {filter}
                    </Text.span>
                  </>
                )}
              </Text>
            </Box>
            {data.map((result: SearchResultTypes, i: number) => {
              return <SearchResult searchResult={result} key={i} />;
            })}
          </Box>
        ) : isValidating ? (
          <IsValidating />
        ) : error ? (
          <IsError error={error.message} />
        ) : (
          <Box m={4}>
            <Flash variant="warning">No results.</Flash>
          </Box>
        )}
      </Box>
    </>
  );
};
