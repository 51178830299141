import { useContext, useState } from "react";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { Button, Field, Flash, Form, Input, Text } from "rimble-ui";
import { colors } from "serto-ui";
import { ErrorMsgSettings } from "../../components";
import { DatabaseTypes } from "../../types";

export interface DatabaseFormProps {
  database?: DatabaseTypes;
  onComplete(): void;
}

export const DatabaseForm: React.FunctionComponent<DatabaseFormProps> = (props) => {
  const { database, onComplete } = props;
  const SertoAgent = useContext(SertoAgentContext);
  const [error, setError] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [host, setHost] = useState<string>(database?.serto.host || "");
  const [username, setUserName] = useState<string>(database?.serto.username || "");
  const [password, setPassword] = useState<string>("");

  const updateDatabase = async () => {
    setError("");
    setLoading(true);

    const database = {
      serto: { host, username, password, database: "serto" },
      veramo: { host, username, password, database: "veramo" },
    };

    try {
      await SertoAgent.updateDatabase(database);
      onComplete();
    } catch (err) {
      console.error("failed to update database:", err);
      setError(<ErrorMsgSettings error={err.message} />);
    }

    setLoading(false);
  };

  return (
    <>
      <Text color={colors.midGray} mb={3}>
        {database
          ? "To update your database, provide the information below."
          : "You are using a testing environment, and storing DIDs and VCs locally. To use a production environment, configure your database below."}
      </Text>
      <Form width="425px">
        <Field label="Host" width="100%">
          <Input
            onChange={(e: any) => setHost(e.target.value)}
            placeholder="Enter database host"
            required
            type="text"
            value={host}
            width="100%"
          />
        </Field>
        <Field label="Username" width="100%">
          <Input
            onChange={(e: any) => setUserName(e.target.value)}
            placeholder="Enter database username"
            required
            type="text"
            value={username}
            width="100%"
          />
        </Field>
        <Field label="Password" width="100%">
          <Input
            onChange={(e: any) => setPassword(e.target.value)}
            placeholder="Enter database password"
            required
            type="text"
            width="100%"
          />
        </Field>
        {error && (
          <Flash mb={3} variant="danger">
            {error}
          </Flash>
        )}
        <Button disabled={loading} onClick={() => updateDatabase()} type="submit" width="100%">
          Save Settings
        </Button>
      </Form>
    </>
  );
};
