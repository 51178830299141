import { CheckCircle } from "@rimble/icons";
import { Box, Flex, Text } from "rimble-ui";
import { colors, H5 } from "serto-ui";
import { ONBOARDING_FLOWS } from "../../views/Home/Onboarding/Onboarding";

export interface OnboardingChecklistItemProps {
  checked: boolean;
  name: string;
}

export const OnboardingChecklistItem: React.FunctionComponent<OnboardingChecklistItemProps> = (props) => {
  return (
    <Flex mb={2}>
      {props.checked ? (
        <CheckCircle color={colors.primary.base} size="22px" />
      ) : (
        <Box border={1} borderRadius="50%" height="22px" width="22px"></Box>
      )}
      <Text ml={2} fontSize={2}>
        {props.name}
      </Text>
    </Flex>
  );
};

export interface OnboardingChecklistProps {
  onboardingFlow?: string;
  step: number;
}

export const OnboardingChecklist: React.FunctionComponent<OnboardingChecklistProps> = (props) => {
  const { onboardingFlow, step } = props;

  return (
    <Box border={2} borderRadius={1} p={3}>
      <H5 mb={3} mt={0}>
        Progress Checklist
      </H5>
      {onboardingFlow === ONBOARDING_FLOWS.ETH ? (
        <>
          <OnboardingChecklistItem checked={step > 0} name="Create Identifier (DID)" />
          <OnboardingChecklistItem checked={step > 2} name="Issue Organization Profile VC" />
          <OnboardingChecklistItem checked={step > 4} name="Link DID to Domain" />
        </>
      ) : (
        <>
          <OnboardingChecklistItem checked={step > 0} name="Create Identifier (DID)" />
          <OnboardingChecklistItem checked={step > 2} name="Issue Organization Profile VC" />
        </>
      )}
    </Box>
  );
};
