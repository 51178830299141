import { useContext, useState } from "react";
import useSWR from "swr";
import { routes, sections } from "../../constants";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { OnboardingTypes } from "../../types";
import { Global, IsValidating } from "../../components";
import { Button } from "rimble-ui";
import { Header, HeaderBox, ModalWithX } from "serto-ui";
import { Dashboard } from "./Dashboard/Dashboard";
import { Onboarding } from "./Onboarding/Onboarding";
import { OnboardingNotice } from "./Onboarding/OnboardingNotice";
import { WelcomeModal } from "./Onboarding/WelcomeModal";

export const HomePage: React.FunctionComponent = () => {
  const SertoAgent = useContext(SertoAgentContext);
  const hasPermissions = SertoAgent.getPermissions() === "admin";

  const { data: onboarding, isValidating, error } = useSWR(
    SertoAgent.api.SETTINGS_ONBOARDING,
    () => SertoAgent.getOnboarding(),
    {
      revalidateOnFocus: false,
    },
  );

  if (error) {
    console.error(error);
  }

  return (
    <Global section={sections.HOMEPAGE} url={routes.HOMEPAGE}>
      {isValidating ? (
        <>
          <HeaderBox>
            <Header heading="Welcome" />
          </HeaderBox>
          <IsValidating />
        </>
      ) : error ? (
        <>
          <HeaderBox>
            <Header heading="Welcome" />
          </HeaderBox>
          <Dashboard />
        </>
      ) : (
        <HomePageContent hasPermissions={hasPermissions} onboarding={onboarding} />
      )}
    </Global>
  );
};

export interface HomePageContentProps {
  hasPermissions: boolean;
  onboarding: OnboardingTypes;
}

export const HomePageContent: React.FunctionComponent<HomePageContentProps> = (props) => {
  const { hasPermissions, onboarding } = props;
  const onboardingStarted = Object.keys(onboarding).length > 0;
  const [showOnboarding, setShowOnboarding] = useState<boolean>(!onboardingStarted);
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState<boolean>(!onboardingStarted);

  return (
    <>
      {showOnboarding ? (
        <>
          <HeaderBox>
            <Header heading="Welcome">
              <Button.Outline onClick={() => setShowOnboarding(false)} size="small">
                Finish Setup Later
              </Button.Outline>
            </Header>
          </HeaderBox>
          <Onboarding onComplete={() => setShowOnboarding(false)} onboarding={onboarding} />
          <ModalWithX
            isOpen={isWelcomeModalOpen}
            close={() => setIsWelcomeModalOpen(false)}
            borderRadius={2}
            maxWidth="1000px"
          >
            <WelcomeModal
              close={() => {
                setIsWelcomeModalOpen(false);
              }}
            />
          </ModalWithX>
        </>
      ) : (
        <>
          <HeaderBox>
            <Header heading="Welcome" />
          </HeaderBox>
          {hasPermissions && onboarding && (
            <OnboardingNotice onboarding={onboarding} startOnboarding={() => setShowOnboarding(true)} />
          )}
          <Dashboard />
        </>
      )}
    </>
  );
};
