import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { createGlobalStyle } from "styled-components";

import { routes } from "./constants";
import { ErrorFallback } from "./components/elements";
import { SertoAgentProvider } from "./context/SertoAgentProvider";
import { fonts, IdentityThemeProvider } from "serto-ui";

import { AuthenticatedRoute } from "./views/Auth/AuthenticatedRoute";
import { IssuedCredentialsPage } from "./views/Credentials/IssuedCredentialsPage";
import { ForgotPasswordPage } from "./views/Auth/ForgotPasswordPage";
import { HomePage } from "./views/Home/HomePage";
import { IdentifiersPage } from "./views/Identifiers/IdentifiersPage";
import { SearchPage } from "./views/Identifiers/Search/SearchPage";
import { LoginPage } from "./views/Auth/LoginPage";
import { DomainsPage } from "./views/Organization/DomainsPage";
import { MembersPage } from "./views/Organization/MemebersPage";
import { ResetPasswordPage } from "./views/Auth/ResetPasswordPage";
import { APIKeyManagementPage } from "./views/Settings/APIKeyManagementPage";
import { DatabasePage } from "./views/Settings/DatabasePage";
import { DidMethodsPage } from "./views/Settings/DidMethodsPage";
import { EmailServerPage } from "./views/Settings/EmailServerPage";
import { ProfilePage } from "./views/Settings/ProfilePage";
import { ReceivedCredentialsPage } from "./views/Credentials/ReceivedCredentialsPage";

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  body {
    background-color: #F6F6FE;
    font-family: ${fonts.sansSerif};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
  }
  *, :after, :before {
    box-sizing: inherit;
  }
`;

export const errorFallback = (): JSX.Element => {
  return <ErrorFallback />;
};

export const App = (): JSX.Element => {
  return (
    <BrowserRouter>
      <React.Suspense fallback={<></>}>
        <GlobalStyle />
        <IdentityThemeProvider>
          <ErrorBoundary FallbackComponent={errorFallback}>
            <SertoAgentProvider>
              <Switch>
                <Route path={routes.LOGIN} component={LoginPage} />
                <Route path={routes.FORGOT_PASSWORD} component={ForgotPasswordPage} />
                <Route path={routes.RESET_PASSWORD} component={ResetPasswordPage} />
                <AuthenticatedRoute exact path={routes.HOMEPAGE} component={HomePage} />
                <AuthenticatedRoute path={routes.CREDENTIALS_ISSUED} component={IssuedCredentialsPage} />
                <AuthenticatedRoute path={routes.CREDENTIALS_RECEIVED} component={ReceivedCredentialsPage} />
                <AuthenticatedRoute path={routes.CREDENTIALS} component={IssuedCredentialsPage} />
                <AuthenticatedRoute path={routes.IDENTIFIERS_SEARCH} component={SearchPage} />
                <AuthenticatedRoute path={routes.IDENTIFIERS_MY_DIDS} component={IdentifiersPage} />
                <AuthenticatedRoute path={routes.IDENTIFIERS} component={IdentifiersPage} />
                <AuthenticatedRoute path={routes.ORGANIZATION_DOMAIN} component={DomainsPage} />
                <AuthenticatedRoute path={routes.ORGANIZATION_MEMBERS} component={MembersPage} />
                <AuthenticatedRoute path={routes.ORGANIZATION} component={DomainsPage} />
                <AuthenticatedRoute path={routes.SETTINGS_API} component={APIKeyManagementPage} />
                <AuthenticatedRoute path={routes.SETTINGS_DATABASE} component={DatabasePage} />
                <AuthenticatedRoute path={routes.SETTINGS_DID_METHODS} component={DidMethodsPage} />
                <AuthenticatedRoute path={routes.SETTINGS_EMAIL} component={EmailServerPage} />
                <AuthenticatedRoute path={routes.SETTINGS_PROFILE} component={ProfilePage} />
                <AuthenticatedRoute path={routes.SETTINGS} component={ProfilePage} />
              </Switch>
            </SertoAgentProvider>
          </ErrorBoundary>
        </IdentityThemeProvider>
      </React.Suspense>
    </BrowserRouter>
  );
};
