import { createContext, useMemo } from "react";
import { FeatureFlagService } from "../services/FeatureFlagService";
import { SertoAgentService } from "../services/SertoAgentService";
import { SertoSearchService } from "../services/SertoSearchService";

// typescript hack to get around having to initialize the object
// @ts-ignore
export const SertoAgentContext = createContext<SertoAgentService>({});
// @ts-ignore
export const SertoSearchContext = createContext<SertoSearchService>({});
// @ts-ignore
export const FeatureFlagContext = createContext<FeatureFlagService>({});

export interface SertoAgentProviderProps {
  featureFlags?: string[];
}

export const SertoAgentProvider: React.FunctionComponent<SertoAgentProviderProps> = ({ children, featureFlags }) => {
  const sertoAgent = useMemo(() => new SertoAgentService(), []);
  const sertoSearch = useMemo(() => new SertoSearchService(), []);
  const features = new FeatureFlagService(featureFlags);

  return (
    <SertoAgentContext.Provider value={sertoAgent}>
      <SertoSearchContext.Provider value={sertoSearch}>
        <FeatureFlagContext.Provider value={features}>{children}</FeatureFlagContext.Provider>
      </SertoSearchContext.Provider>
    </SertoAgentContext.Provider>
  );
};
