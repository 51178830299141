import { useState, useEffect } from "react";
import { CheckCircle } from "@rimble/icons";
import { Box, Button, Field, Flash, Flex, Form, Input, Text } from "rimble-ui";
import { colors } from "serto-ui";

export interface PasswordTypes {
  currentPassword?: string;
  newPassword: string;
  confirmNewPassword: string;
}

export interface PasswordProps {
  btnText?: string;
  error?: any;
  checkCurrentPassword?: boolean;
  loading?: boolean;
  cancel?(): void;
  setPassword(password: string, currentPassword?: string): void;
}

export const Password: React.FunctionComponent<PasswordProps> = (props) => {
  const [password, setPassword] = useState<PasswordTypes>({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const requiredLength = 12;
  const [validLength, setValidLength] = useState<boolean>(false);
  const [hasNumber, setHasNumber] = useState<boolean>(false);
  const [upperCase, setUpperCase] = useState<boolean>(false);
  const [lowerCase, setLowerCase] = useState<boolean>(false);
  const [specialChar, setSpecialChar] = useState<boolean>(false);
  const [match, setMatch] = useState(false);

  useEffect(() => {
    setValidLength(password.newPassword.length >= requiredLength);
    setUpperCase(password.newPassword.toLowerCase() !== password.newPassword);
    setLowerCase(password.newPassword.toUpperCase() !== password.newPassword);
    setHasNumber(/\d/.test(password.newPassword));
    setMatch(!!password.newPassword && password.newPassword === password.confirmNewPassword);
    setSpecialChar(/[ `!@#$%^&*()_+/\-=\]{};':"\\|,.<>?~]/.test(password.newPassword));
  }, [password, requiredLength]);

  return (
    <>
      <Box mb={3}>
        <Text mb={2}>Password requirements:</Text>
        <Flex alignItems="center" mb={1}>
          <CheckCircle color={validLength ? colors.success.base : colors.lightGray} mr={2} />
          <Text>At least 12 characters</Text>
        </Flex>
        <Flex alignItems="center" mb={1}>
          <CheckCircle color={lowerCase ? colors.success.base : colors.lightGray} mr={2} />
          <Text>A lowercase letter</Text>
        </Flex>
        <Flex alignItems="center" mb={1}>
          <CheckCircle color={upperCase ? colors.success.base : colors.lightGray} mr={2} />
          <Text>An uppercase letter</Text>
        </Flex>
        <Flex alignItems="center" mb={1}>
          <CheckCircle color={hasNumber ? colors.success.base : colors.lightGray} mr={2} />
          <Text>A number</Text>
        </Flex>
        <Flex alignItems="center" mb={1}>
          <CheckCircle color={specialChar ? colors.success.base : colors.lightGray} mr={2} />
          <Text>A symbol</Text>
        </Flex>
      </Box>
      <Form>
        {props.checkCurrentPassword && (
          <Field width="100%" label="Current Password *">
            <Input
              autocomplete="current-password"
              type="password"
              width="100%"
              required
              onChange={(e: any) =>
                setPassword({
                  ...password,
                  ["currentPassword"]: e.target.value,
                })
              }
            />
          </Field>
        )}
        <Field width="100%" label="New Password *">
          <Input
            autocomplete="new-password"
            placeholder="Enter a strong password"
            type="password"
            width="100%"
            required
            onChange={(e: any) =>
              setPassword({
                ...password,
                ["newPassword"]: e.target.value,
              })
            }
          />
        </Field>
        <Field width="100%" label="Confirm New Password *">
          <Input
            autocomplete="new-password"
            placeholder="Enter your new password again"
            type="password"
            width="100%"
            required
            onChange={(e: any) =>
              setPassword({
                ...password,
                ["confirmNewPassword"]: e.target.value,
              })
            }
          />
        </Field>
        {match && (
          <Flex alignItems="center" mb={3}>
            <CheckCircle color={colors.success.base} mr={2} />
            <Text>Passwords match</Text>
          </Flex>
        )}
        {props.error && (
          <Flash mb={3} variant="danger">
            {props.error}
          </Flash>
        )}
        <Flex>
          {props.cancel && (
            <Button.Outline onClick={() => props.cancel && props.cancel()} mr={3} width="140px">
              Cancel
            </Button.Outline>
          )}
          <Button
            disabled={props.loading}
            onClick={() => props.setPassword(password.newPassword, password.currentPassword)}
            type="submit"
            width="100%"
          >
            {props.btnText || "Reset Password"}
          </Button>
        </Flex>
      </Form>
    </>
  );
};
