import { useContext, useState } from "react";
import useSWR from "swr";
import { links } from "../../constants";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { Box, Button, Flash, Flex, Text } from "rimble-ui";
import { IsSuccess, IsValidating, SecondaryHeader } from "../../components";
import { DatabaseForm } from "./DatabaseForm";
import { baseColors, colors } from "serto-ui";

export const Database: React.FunctionComponent = () => {
  const SertoAgent = useContext(SertoAgentContext);
  const [success, setSuccess] = useState<boolean>(false);

  const { data: database, isValidating } = useSWR(
    SertoAgent.api.SETTINGS_DATABASE_CONFIG,
    () => SertoAgent.getDatabase(),
    {
      revalidateOnFocus: false,
    },
  );

  const onSuccess = () => {
    setSuccess(true);
    setTimeout(() => {
      SertoAgent.logout();
    }, 3000);
  };

  return (
    <>
      <SecondaryHeader heading="Database Setup" />
      <Box borderTop={2} p={4}>
        {success ? (
          <Flex justifyContent="center" width="100%">
            <Box mt={5} maxWidth="620px">
              <IsSuccess loader text="Success!">
                <Text textAlign="center">You will be logged out in 3 seconds.</Text>
                <Text textAlign="center">
                  Please log back in to Serto Agent using your database username and password.
                </Text>
              </IsSuccess>
            </Box>
          </Flex>
        ) : isValidating ? (
          <IsValidating />
        ) : (
          <>
            {/* TODO we currently don't have a way to know if they are using a free or paid version */}
            <Text color={colors.midGray} mb={3}>
              KMS integration and PostGres Database are not included in the free version.
            </Text>
            <Flash mb={5} variant="info">
              <Text color={baseColors.black} fontWeight={3} mb={3}>
                Upgrade to Enterprise plan to improve security
              </Text>
              <Text color={colors.midGray} mb={3}>
                Built-in Key Management System (KMS), and PostGres database intergration.
              </Text>
              <Button as="a" href={links.AWS_MARKETPLACE_PAID} size="small">
                Upgrade to Pro
              </Button>
            </Flash>
            <DatabaseForm database={database && database} onComplete={() => onSuccess()} />
          </>
        )}
      </Box>
    </>
  );
};
