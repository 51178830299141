import { useContext, useState } from "react";
import { mutate } from "swr";
import { SertoAgentContext } from "../../../context/SertoAgentProvider";
import { Box, Button, Flash, Flex, Form, Input, Text } from "rimble-ui";
import { colors } from "serto-ui";
import {
  DidConfigExplainer,
  ErrorMsgDidConfig,
  FormField,
  FormHelperText,
  FormLabel,
  OnboardingWidget,
  OnboardingWidgetDescription,
} from "../../../components";

export interface GenerateDIDConfigProps {
  did: any;
  domain: string;
  onboardingFlow: string;
  onComplete(): void;
}

export const GenerateDIDConfig: React.FunctionComponent<GenerateDIDConfigProps> = (props) => {
  const SertoAgent = useContext(SertoAgentContext);
  const [error, setError] = useState<any | undefined>();
  const [domain, setDomain] = useState<string>(props.domain);
  const did = typeof props.did === "string" ? props.did : props.did.did;
  const dids: any = [did];

  async function createDidConfig(e: Event) {
    e.preventDefault();
    setError("");
    try {
      await SertoAgent.createDidConfig(domain, dids);
      mutate(SertoAgent.api.DID_CONFIG);
      props.onComplete();
    } catch (err) {
      console.error("failed to create did config:", err);
      setError(<ErrorMsgDidConfig error={err.message} />);
      return;
    }
  }

  return (
    <OnboardingWidget
      onboardingFlow={props.onboardingFlow}
      step={4}
      stepTitleNumber={3}
      title="Link identifier (DID) to organization’s domain"
    >
      <OnboardingWidgetDescription>
        This lets others find and verify your identity. First, you will create a DID configuration file. Then, you need
        to upload it to your organization’s domain (we’ll let you know where).
      </OnboardingWidgetDescription>
      <Form maxWidth="424px" onSubmit={createDidConfig}>
        <Box mb={5}>
          <FormField>
            <FormLabel>Organization Domain</FormLabel>
            <Input
              disabled={!props.domain}
              mb={3}
              onChange={(e: any) => setDomain(e.target.value)}
              value={domain}
              width="100%"
            />
          </FormField>
          <Box mb={4}>
            <FormField>
              <FormLabel>Your Identifier (DID)</FormLabel>
              <Flex
                alignItems="center"
                bg={colors.nearWhite}
                border={1}
                borderRadius={1}
                height="48px"
                px={3}
                py={2}
                style={{ overflow: "hidden" }}
                title={did}
              >
                <Text color={colors.silver}>{did}</Text>
              </Flex>
              <FormHelperText>{did.alias}</FormHelperText>
            </FormField>
          </Box>
          <DidConfigExplainer />
          {error && (
            <Flash mb={3} variant="danger">
              {error}
            </Flash>
          )}
        </Box>
        <Button type="submit" width="100%">
          Generate DID Config file
        </Button>
      </Form>
    </OnboardingWidget>
  );
};
