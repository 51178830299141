import { Box, Text } from "rimble-ui";
import { colors } from "serto-ui";

// TODO: standardize form components and validation in serto-ui

export const RequireFieldText: React.FunctionComponent = () => {
  return (
    <Text color={colors.gray60} fontSize={1} mb={3}>
      * Indicates a required field
    </Text>
  );
};

export interface FormFieldProps {
  [key: string]: any;
}

export const FormField: React.FunctionComponent<FormFieldProps> = (props) => {
  return (
    <Box mb={3} {...props}>
      {props.children}
    </Box>
  );
};

export const FormLabel: React.FunctionComponent = (props) => {
  return (
    <Text fontSize={1} fontWeight={4} mb={1}>
      {props.children}
    </Text>
  );
};

export const FormLabelDescription: React.FunctionComponent = (props) => {
  return (
    <Text color={colors.gray60} fontSize={1} mb={2}>
      {props.children}
    </Text>
  );
};

export const FormHelperText: React.FunctionComponent = (props) => {
  return (
    <Text color={colors.silver} fontSize={0} mt={1}>
      {props.children}
    </Text>
  );
};
