import { Box } from "rimble-ui";
import { baseColors } from "serto-ui";
import { DashboardCount } from "./DashboardCount";
import { DashboardActivityFeed } from "./DashboardActivityFeed";

export const Dashboard: React.FunctionComponent = () => {
  return (
    <>
      <DashboardCount />
      <Box bg={baseColors.white} borderRadius={1} boxShadow={1} my={3} py={1}>
        <DashboardActivityFeed />
      </Box>
    </>
  );
};
