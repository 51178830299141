import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { KeyboardArrowDown, KeyboardArrowUp } from "@rimble/icons";
import { Box, Flex } from "rimble-ui";
import { baseColors, colors } from "serto-ui";
import { DidMethodOption, DidMethodOptionTitle } from "./DidMethodDropDownComponents";

const Option = styled(Box)`
  &:hover {
    background-color: ${colors.primary.background};
  }
`;

export interface DidMethodDropDownProps {
  didMethods: string[];
  defaultSelectedValue?: string;
  disabled?: boolean;
  showRecommended?: boolean;
  onChange(value: string): void;
}

export const DidMethodDropDown: React.FunctionComponent<DidMethodDropDownProps> = (props) => {
  const node = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [selectedOption, setSelectedOption] = useState(props.defaultSelectedValue || props.didMethods[0]);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => !props.disabled && setIsOpen(!isOpen);

  const onOptionClicked = (option: string) => () => {
    setSelectedOption(option);
    setIsOpen(false);
    props.onChange(option);
  };

  const onClickOutside = (e: any) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", onClickOutside);
    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  });

  return (
    <Box
      ref={node}
      bg={baseColors.white}
      border={isOpen ? 3 : 1}
      borderBottom={isOpen && 0}
      borderRadius={1}
      borderBottomLeftRadius={isOpen && 0}
      borderBottomRightRadius={isOpen && 0}
      boxShadow={1}
      width="100%"
      style={{
        position: "relative",
        zIndex: isOpen ? 2 : 1,
        opacity: props.disabled ? 0.4 : 1,
      }}
    >
      <Flex
        onClick={toggle}
        alignItems="center"
        height="48px"
        p={3}
        pr={5}
        width="100%"
        style={{
          cursor: props.disabled ? "default" : "pointer",
          position: "relative",
        }}
      >
        <DidMethodOptionTitle
          didMethod={selectedOption}
          showMessaging={false}
          showRecommended={props.showRecommended}
        />
        {isOpen ? (
          <KeyboardArrowUp
            color={colors.darkGray}
            style={{ position: "absolute", right: "8px", top: "calc(50% - 12px)" }}
          />
        ) : (
          <KeyboardArrowDown
            color={colors.darkGray}
            style={{ position: "absolute", right: "8px", top: "calc(50% - 12px)" }}
          />
        )}
      </Flex>
      {isOpen && (
        <Box
          bg={baseColors.white}
          border={3}
          borderTop={0}
          borderBottomLeftRadius={1}
          borderBottomRightRadius={1}
          boxShadow={1}
          width="calc(100% + 2px)"
          style={{ position: "absolute", left: "-1px" }}
        >
          <Box>
            {props.didMethods.map((didMethod: any, key: number) => {
              return (
                <Option
                  onClick={onOptionClicked(didMethod)}
                  key={key}
                  borderTop={2}
                  p={3}
                  style={{ cursor: "pointer" }}
                >
                  <DidMethodOption didMethod={didMethod} />
                </Option>
              );
            })}
          </Box>
          {props.children}
        </Box>
      )}
    </Box>
  );
};
