import { config } from "./config";

export const domainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,63}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
export const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,24}(:[0-9]{1,5})?(\/.*)?$/;
export const ipAddressRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const awsRegex = /^ec2-.*\.amazonaws\.com$/;

export enum routes {
  HOMEPAGE = "/",
  LOGIN = "/login",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password/:jwt?",
  CREDENTIALS = "/credentials",
  CREDENTIALS_ISSUED = "/credentials/issued",
  CREDENTIALS_RECEIVED = "/credentials/received",
  DOMAIN = "/domain/:domain",
  IDENTIFIERS = "/identifiers",
  IDENTIFIERS_MY_DIDS = "/identifiers/my-dids",
  IDENTIFIERS_SEARCH = "/identifiers/search",
  ORGANIZATION = "/organization",
  ORGANIZATION_DOMAIN = "/organization/domain",
  ORGANIZATION_MEMBERS = "/organization/members",
  SCHEMAS = "/schemas",
  SETTINGS = "/settings",
  SETTINGS_API = "/settings/api-keys",
  SETTINGS_DID_METHODS = "/settings/did-methods",
  SETTINGS_DATABASE = "/settings/database",
  SETTINGS_EMAIL = "/settings/email-server",
  SETTINGS_PROFILE = "/settings/my-profile",
}

export enum sections {
  HOMEPAGE = "home",
  CREDENTIALS = "credentials",
  IDENTIFIERS = "identifiers",
  ORGANIZATION = "organization",
  SCHEMAS = "schemas",
  SETTINGS = "settings",
}

const DOC_BASE_URL = `${config.AGENT_API_URL}/docs/`;

export const links = {
  EMAIL_SUPPORT: "help@consensys.id",
  DOCUMENTATION: DOC_BASE_URL,
  SEARCH: config.SEARCH_UI_URL,
  SCHEMAS: config.SCHEMAS_UI_URL,
  TERMS: "https://consensys.net/terms-of-use/",

  // AWS
  AWS_MARKETPLACE_FREE:
    "https://aws.amazon.com/marketplace/pp/prodview-x55rqwei63kuw?sr=0-1&ref_=beagle&applicationId=AWSMPContessa",
  AWS_MARKETPLACE_PAID:
    "https://aws.amazon.com/marketplace/pp/prodview-wutgo3bpidol4?sr=0-2&ref_=beagle&applicationId=AWSMPContessa",

  // Documentation
  DOC_DNS_SETUP: "https://docs.serto.id/docs/serto-agent/aws-deploy/#dns",
  DOC_DIDS: "https://docs.serto.id/docs/main-concepts/dids",
};

export const featureFlags = {};

export enum DidConfigStatusLabel {
  NOT_VERIFIED = "notVerified",
  NOT_PUBLISHED = "notPublished",
  IN_SYNC = "synced",
  OUT_OF_SYNC = "outOfSync",
  OUT_DATED = "outDated",
}
