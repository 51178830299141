import { Flex, Text } from "rimble-ui";
import { MinimalLayout } from "../../components";

// TODO: need design/messaging for this
export const ErrorFallback: React.FunctionComponent = () => {
  return (
    <MinimalLayout>
      <Flex alignItems="center" height="calc(80vh - 72px)" justifyContent="center">
        <Text textAlign="center">An error has occured. Please refresh the page and try again.</Text>
      </Flex>
    </MinimalLayout>
  );
};
