import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { Box, Flex, Text } from "rimble-ui";
import { H3 } from "serto-ui";
import { IsSuccess, LoginLayout } from "../../components";
import { ErrorMsgAuth } from "../../components/text";
import { Password } from "./Password";

export const ResetPasswordPage: React.FunctionComponent = () => {
  const SertoAgent = useContext(SertoAgentContext);
  const [error, setError] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { jwt } = useParams<{ jwt: string }>();

  async function resetPassword(password: string) {
    setError(false);
    setLoading(true);

    try {
      await SertoAgent.resetPassword(jwt, password);
      setSuccess(true);
      setTimeout(() => {
        SertoAgent.logout();
      }, 3000);
    } catch (err) {
      console.error("error resetting password:", err);
      setError(<ErrorMsgAuth error={err.message} />);
    }
    setLoading(false);
  }

  return (
    <LoginLayout>
      {success ? (
        <Flex justifyContent="center" width="100%">
          <Box mt={5} maxWidth="620px">
            <IsSuccess loader text="Success!">
              <Text textAlign="center" mb={4}>
                You are being redirected to log into Serto Agent.
              </Text>
            </IsSuccess>
          </Box>
        </Flex>
      ) : (
        <>
          <H3 mt={0}>Reset Password</H3>
          <Password error={error} loading={loading} setPassword={(password: string) => resetPassword(password)} />
        </>
      )}
    </LoginLayout>
  );
};
