import { useContext, useState } from "react";
import { SertoSearchContext } from "../../../context/SertoAgentProvider";
import { Box, Button, Flash, Flex, Text } from "rimble-ui";
import { baseColors, H4 } from "serto-ui";
import { ErrorMsgSearch, IsSuccess } from "../../../components";

export interface MakeOrgSearchableProps {
  domain: string;
  onComplete(): void;
}

export const MakeOrgSearchable: React.FunctionComponent<MakeOrgSearchableProps> = (props) => {
  const SertoSearch = useContext(SertoSearchContext);
  const [error, setError] = useState<any | undefined>();
  const [success, setSuccess] = useState<boolean>(false);

  async function registerDomain() {
    setError("");

    try {
      await SertoSearch.registerDomain(props.domain);
      setSuccess(true);
    } catch (err) {
      console.error("failed to add domain to search:", err);
      setError(<ErrorMsgSearch error={err.message} />);
      return;
    }
  }

  return (
    <>
      {!success ? (
        <Box bg={baseColors.white} borderRadius={1} height="100%" px={4} py={7}>
          <Flex justifyContent="center">
            <Box textAlign="center" width="600px">
              <H4 mb={1} mt={0}>
                Would you like to make your organization searchable?
              </H4>
              <Text fontSize={1} mb={5} textAlign="center">
                This will allow others to find your organization’s DID on Serto Search. You may also manage this option
                in the Organization tab at any time.
              </Text>
              {error && (
                <Flash mb={3} variant="danger">
                  {error}
                </Flash>
              )}
              <Box textAlign="center" width="100%">
                <Button mb={3} onClick={registerDomain} width="300px">
                  Yes, Make My Org Searchable
                </Button>
              </Box>
              <Box textAlign="center" width="100%">
                <Button.Text onClick={() => props.onComplete()} width="300px">
                  Not at this time
                </Button.Text>
              </Box>
            </Box>
          </Flex>
        </Box>
      ) : (
        <Flex
          bg={baseColors.white}
          borderRadius={1}
          flexDirection="colmun"
          height="calc(100vh - 200px)"
          justifyContent="center"
          p={5}
        >
          <Box mt={6}>
            <IsSuccess text="Success!" onClick={() => props.onComplete()}>
              <Text fontSize={2} fontWeight={3} textAlign="center">
                Your organization is now searchable.
              </Text>
            </IsSuccess>
          </Box>
        </Flex>
      )}
    </>
  );
};
