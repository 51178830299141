import { useContext, useState } from "react";
import useSWR from "swr";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { links } from "../../constants";
import { AddCircle, OpenInNew } from "@rimble/icons";
import { Button, Flex, Table, Text } from "rimble-ui";
import { colors, ModalWithX, TBody, TH, THead, TR } from "serto-ui";
import { ErrorMsgSettings, IsError, IsValidating, NoAPIKeysText, NoData, SecondaryHeader } from "../../components";
import { APIKeyManagementDelete } from "./APIKeyManagementDelete";
import { APIKeyManagementCreate } from "./APIKeyManagementCreate";

export const APIKeyManagement: React.FunctionComponent = () => {
  const SertoAgent = useContext(SertoAgentContext);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [apiKeyToDeleteName, setApiKeyToDeleteName] = useState<string>("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const { data: apiKeys, error, isValidating } = useSWR(SertoAgent.api.API_KEYS, () => SertoAgent.getApiKeys(), {
    revalidateOnFocus: false,
  });

  const onCloseModal = () => {
    setIsCreateModalOpen(false);
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <SecondaryHeader
        heading="API Keys"
        cta={
          <Flex>
            <Button.Outline as="a" href={links.DOCUMENTATION} mr={2} size="small" target="_blank" title="Documentation">
              View Documentation <OpenInNew ml={1} size="16px" />
            </Button.Outline>
            <Button onClick={() => setIsCreateModalOpen(true)} size="small">
              <AddCircle size="14px" mr={1} color={colors.primary.disabled[1]} />
              Create API Key
            </Button>
          </Flex>
        }
      />
      {isValidating ? (
        <IsValidating />
      ) : error ? (
        <IsError error={<ErrorMsgSettings error={error.toString()} />} />
      ) : apiKeys?.length ? (
        <>
          <Table border={0} boxShadow={0} width="100%">
            <THead>
              <TR>
                <TH>Name</TH>
                <TH>Hash (last 4)</TH>
                <TH />
              </TR>
            </THead>
            <TBody>
              {apiKeys.map((apiKey: any, i: number) => {
                return (
                  <TR key={i}>
                    <td>
                      <Text.span fontWeight={3}>{apiKey.name}</Text.span>
                    </td>
                    <td>{apiKey.last4}</td>
                    <td style={{ textAlign: "right" }}>
                      <Button
                        onClick={() => {
                          setApiKeyToDeleteName(apiKey.name);
                          setIsDeleteModalOpen(true);
                        }}
                        size="small"
                        variant="warning"
                      >
                        Delete
                      </Button>
                    </td>
                  </TR>
                );
              })}
            </TBody>
          </Table>
        </>
      ) : (
        <NoData text={<NoAPIKeysText />} />
      )}

      <ModalWithX isOpen={isCreateModalOpen} close={() => setIsCreateModalOpen(false)} borderRadius={2} width="425px">
        <APIKeyManagementCreate close={() => onCloseModal()} />
      </ModalWithX>

      <ModalWithX isOpen={isDeleteModalOpen} close={() => setIsDeleteModalOpen(false)} borderRadius={2} width="425px">
        <APIKeyManagementDelete apiName={apiKeyToDeleteName} close={() => onCloseModal()} />
      </ModalWithX>
    </>
  );
};
