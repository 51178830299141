import { Box, Button, Flex, Text } from "rimble-ui";
import { colors, H2, H5, ModalContent } from "serto-ui";
import { welcomeStep1Img, welcomeStep2Img, welcomeStep3Img } from "../../../components";

export interface WelcomeModalProps {
  close(): void;
}

export const WelcomeModal: React.FunctionComponent<WelcomeModalProps> = (props) => {
  return (
    <>
      <ModalContent>
        <H2 my={0} textAlign="center">
          Welcome to Serto Agent
        </H2>
        <Text mb={5} textAlign="center">
          Let’s get your Agent fully set up!
        </Text>
        <Flex mb={4} justifyContent="space-between">
          <Box p={2} width="33%" textAlign="center">
            <Box mb={3}>
              <img src={welcomeStep1Img} alt="Step 1" height="150px" width="auto" />
            </Box>
            <H5 my={0}>1. Generate Identifier (DID)</H5>
            <Text color={colors.midGray} fontSize={1}>
              Serto Agent will generate your DID. Use this DID to issue, receive, request, and disclose verifiable
              credentials on behalf of your organization.
            </Text>
          </Box>
          <Box p={2} width="33%" textAlign="center">
            <Box mb={3}>
              <img src={welcomeStep2Img} alt="Step 2" height="150px" width="auto" />
            </Box>
            <H5 my={0}>2. Issue your first Verifiable Credendial</H5>
            <Text color={colors.midGray} fontSize={1}>
              With your new DID, you will sign and issue your first Verifiable Credential (VC). This VC will be your
              organization profile.
            </Text>
          </Box>
          <Box p={2} width="33%" textAlign="center">
            <Box mb={3}>
              <img src={welcomeStep3Img} alt="Step 3" height="150px" width="auto" />
            </Box>
            <H5 my={0}>3. Link DID to your domain</H5>
            <Text color={colors.midGray} fontSize={1}>
              Lastly, generate a DID Configuration file and upload it to your website. This file contains your DIDs, and
              a cryptographic proof that ties those identifiers to your web domain.
            </Text>
          </Box>
        </Flex>
        <Box m="0 auto" width="250px">
          <Button onClick={() => props.close()} width="100%">
            Let's Go
          </Button>
        </Box>
      </ModalContent>
    </>
  );
};
