import { useContext, useState } from "react";
import { mutate } from "swr";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { DidConfigStatusLabel } from "../../constants";
import { InfoOutline, Refresh } from "@rimble/icons";
import { Flex, Loader, Text, Tooltip } from "rimble-ui";
import { colors } from "serto-ui";
import { DidConfigStatusDescription } from "../text";

export interface DidConfigStatusMinimalContainerProps {
  label: string;
  loading: boolean;
  status?: string;
  variant?: string;
  onClick(): void;
}

export const DidConfigStatusMinimalContainer: React.FunctionComponent<DidConfigStatusMinimalContainerProps> = (
  props,
) => {
  const { label, loading, status, variant } = props;
  const txtColor =
    variant === "danger"
      ? colors.danger.base
      : variant === "success"
      ? colors.success.base
      : variant === "warning"
      ? colors.warning.base
      : colors.gray60;
  const iconColor =
    variant === "danger"
      ? colors.danger.dark
      : variant === "success"
      ? colors.success.dark
      : variant === "warning"
      ? colors.warning.dark
      : colors.gray60;

  return (
    <Flex alignItems="center">
      <Flex alignItems="center" onClick={props.onClick} style={{ cursor: "pointer" }} title="Refresh Status">
        {loading ? <Loader color={iconColor} mr={1} size="24px" /> : <Refresh color={iconColor} mr={1} size="24px" />}
        <Text color={txtColor} fontSize={1} fontWeight={4} mr={1}>
          {label}
        </Text>
      </Flex>
      {status && (
        <Tooltip message={DidConfigStatusDescription(status)}>
          <InfoOutline color={colors.silver} size="18px" />
        </Tooltip>
      )}
    </Flex>
  );
};

export interface DidConfigStatusMinimalProps {
  domain: string;
  status: string;
}

export const DidConfigStatusMinimal: React.FunctionComponent<DidConfigStatusMinimalProps> = (props) => {
  const { domain, status } = props;
  const SertoAgent = useContext(SertoAgentContext);
  const [loading, setLoading] = useState<boolean>(false);

  async function refreshDidConfigStatus() {
    setLoading(true);
    try {
      await SertoAgent.refreshDidConfigStatus(domain);
      mutate(SertoAgent.api.DID_CONFIG);
      console.log("refreshDidConfigStatus");
    } catch (err) {
      console.error("failed to refresh did config:", err);
    }
    setLoading(false);
  }

  if (status === DidConfigStatusLabel.NOT_VERIFIED) {
    return (
      <DidConfigStatusMinimalContainer
        label="Waiting for Verification"
        loading={loading}
        onClick={() => refreshDidConfigStatus()}
        status={status}
        variant="warning"
      />
    );
  }
  if (status === DidConfigStatusLabel.NOT_PUBLISHED) {
    return (
      <DidConfigStatusMinimalContainer
        label="Not Published"
        loading={loading}
        onClick={() => refreshDidConfigStatus()}
        status={status}
        variant="danger"
      />
    );
  }
  if (status === DidConfigStatusLabel.IN_SYNC) {
    return (
      <DidConfigStatusMinimalContainer
        label="In Sync"
        loading={loading}
        onClick={() => refreshDidConfigStatus()}
        status={status}
        variant="success"
      />
    );
  }
  if (status === DidConfigStatusLabel.OUT_OF_SYNC) {
    return (
      <DidConfigStatusMinimalContainer
        label="Out of Sync"
        loading={loading}
        onClick={() => refreshDidConfigStatus()}
        status={status}
        variant="warning"
      />
    );
  }
  if (status === DidConfigStatusLabel.OUT_DATED) {
    return (
      <DidConfigStatusMinimalContainer
        label="Partially Synced"
        loading={loading}
        onClick={() => refreshDidConfigStatus()}
        status={status}
        variant="warning"
      />
    );
  }
  return (
    <DidConfigStatusMinimalContainer
      label="Status not found"
      loading={loading}
      onClick={() => refreshDidConfigStatus()}
    />
  );
};
