import { useContext, useState } from "react";
import { mutate } from "swr";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { CheckCircle, Warning } from "@rimble/icons";
import { Box, Button, Text } from "rimble-ui";
import { colors, H5 } from "serto-ui";
import { DidConfigStatusLabel } from "../../constants";
import { DidConfigStatusDescription } from "../text";

export interface DidConfigStatusComponentProps {
  domain: string;
  icon: JSX.Element;
  status: string;
  onRefreshStatus(): void;
}

export const DidConfigStatusComponent: React.FunctionComponent<DidConfigStatusComponentProps> = (props) => {
  return (
    <Box py={3} position="relative">
      <Box position="absolute" top={2}>
        {props.icon}
      </Box>
      <Box ml="42px">
        <H5 color={colors.midGray} my={0}>
          Sync status: {props.status}
        </H5>
        <Text color={colors.midGray} fontSize={2} mb={3}>
          {DidConfigStatusDescription(props.status)}
        </Text>
        <Button.Outline onClick={() => props.onRefreshStatus()} size="small" width="200px">
          Refresh Status
        </Button.Outline>
      </Box>
    </Box>
  );
};

export interface DidConfigStatusProps {
  domain: string;
  status: string;
  getUpdatedStatus?(status: string): void;
}

export const DidConfigStatus: React.FunctionComponent<DidConfigStatusProps> = (props) => {
  const [status, setStatus] = useState<string>(props.status);
  const SertoAgent = useContext(SertoAgentContext);

  async function refreshDidConfigStatus() {
    try {
      const resp = await SertoAgent.refreshDidConfigStatus(props.domain);
      mutate(SertoAgent.api.DID_CONFIG);
      setStatus(resp.status);
      props.getUpdatedStatus && props.getUpdatedStatus(resp.status);
    } catch (err) {
      console.error("failed to refresh did config:", err);
    }
  }

  if (status === DidConfigStatusLabel.NOT_PUBLISHED) {
    return (
      <DidConfigStatusComponent
        domain={props.domain}
        icon={<Warning color={colors.danger.base} size="32px" />}
        onRefreshStatus={() => refreshDidConfigStatus()}
        status="Not Published"
      />
    );
  }

  if (status === DidConfigStatusLabel.IN_SYNC) {
    return (
      <DidConfigStatusComponent
        domain={props.domain}
        icon={<CheckCircle color={colors.success.base} size="32px" />}
        onRefreshStatus={() => refreshDidConfigStatus()}
        status="In Sync"
      />
    );
  }

  if (status === DidConfigStatusLabel.OUT_OF_SYNC) {
    return (
      <DidConfigStatusComponent
        domain={props.domain}
        icon={<Warning color={colors.warning.base} size="32px" />}
        onRefreshStatus={() => refreshDidConfigStatus()}
        status="Out of Sync"
      />
    );
  }

  if (status === DidConfigStatusLabel.OUT_DATED) {
    return (
      <DidConfigStatusComponent
        domain={props.domain}
        icon={<CheckCircle color={colors.warning.base} size="32px" />}
        onRefreshStatus={() => refreshDidConfigStatus()}
        status="Partially Synced"
      />
    );
  }

  return (
    <DidConfigStatusComponent
      domain={props.domain}
      icon={<Warning color={colors.warning.base} size="32px" />}
      onRefreshStatus={() => refreshDidConfigStatus()}
      status="Not Verified"
    />
  );
};
