import { useContext } from "react";
import useSWR from "swr";
import { SertoAgentContext } from "../../../context/SertoAgentProvider";
import { Box, Flex, Link, Text } from "rimble-ui";
import { baseColors, colors } from "serto-ui";

export interface DashboardCountItemProps {
  count: number;
  label: string;
  link: string;
  linkText?: string | JSX.Element;
}

export const DashboardCountItem: React.FunctionComponent<DashboardCountItemProps> = (props) => {
  return (
    <Flex
      alignContent="space-between"
      flexDirection="column"
      bg={baseColors.white}
      borderRadius={1}
      boxShadow={1}
      position="relative"
      width="32%"
    >
      <Box p={3}>
        <Text color={colors.gray60} fontSize={1} fontWeight={3}>
          {props.label}
        </Text>
        <Text color={colors.primary.base} fontSize={6} fontWeight={3}>
          {props.count}
        </Text>
      </Box>
      <Flex alignItems="flex-end" borderTop={2} justifyContent="flex-end" p={3}>
        <Link href={props.link} color={colors.primary.base} fontWeight={4}>
          {props.linkText || "See all"}
        </Link>
      </Flex>
    </Flex>
  );
};

export const DashboardCount: React.FunctionComponent = () => {
  const SertoAgent = useContext(SertoAgentContext);

  const { data: dids } = useSWR(SertoAgent.api.VERAMO_GET_DIDS, () => SertoAgent.getDids(), {
    revalidateOnFocus: false,
  });
  const { data: domains } = useSWR(SertoAgent.api.DID_CONFIG, () => SertoAgent.getAllDidConfigs(), {
    revalidateOnFocus: false,
  });
  const { data: credentials } = useSWR(SertoAgent.api.VERAMO_GET_CREDENTIALS, () => SertoAgent.getCredentials(), {
    revalidateOnFocus: false,
  });

  return (
    <Flex justifyContent="space-between">
      <DashboardCountItem count={dids?.length || 0} label="DIDs" link="/identifiers" />
      <DashboardCountItem
        count={(domains && Object.entries(domains)?.length) || 0}
        label="Domains"
        link="/organization/domains"
      />
      <DashboardCountItem count={credentials?.length || 0} label="Credentials" link="/credentials/issued" />
    </Flex>
  );
};
