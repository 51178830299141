import { Card, Flex, Text } from "rimble-ui";
import { colors, SertoAgentLogo } from "serto-ui";
import { links } from "../../constants";

export interface LoginLayoutProps {
  showTerms?: boolean;
}

export const LoginLayout: React.FunctionComponent<LoginLayoutProps> = (props) => {
  return (
    <Flex alignItems="center" bg={colors.primary.base} height="100vh" justifyContent="center" width="100%">
      <Card borderRadius={5} p={6} pt={5} width="525px">
        <Flex alignItems="center" justifyContent="center" mb={5} mt={3} width="100%">
          <SertoAgentLogo />
        </Flex>
        {props.children}
        {props.showTerms && (
          <Text color={colors.silver} fontSize={1} mb={5}>
            By signing up you agree to Serto’s{" "}
            <a href={links.TERMS} target="_blank">
              Terms of Service
            </a>
          </Text>
        )}
      </Card>
    </Flex>
  );
};
