import { useContext, useState, Fragment } from "react";
import useSWR, { mutate } from "swr";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { Box, Button, Checkbox, Flash, Form, Link, Text } from "rimble-ui";
import { FormField, IsError, IsValidating, SecondaryHeader } from "../../components";
import { DidMethodTypes } from "../../types";
import { H4, SertoUiContext } from "serto-ui";

export const DidMethods: React.FunctionComponent = () => {
  const SertoAgent = useContext(SertoAgentContext);
  const { data: didMethods, error, isValidating } = useSWR(
    SertoAgent.api.SETTINGS_DID_METHODS,
    () => SertoAgent.getDidMethods(),
    {
      revalidateOnFocus: false,
    },
  );

  return (
    <>
      <SecondaryHeader heading="DID Methods" />
      <Box borderTop={2} p={4}>
        <Text maxWidth="600px" mb={4}>
          Each{" "}
          <Link href="https://www.w3.org/TR/did-core/#dfn-did-methods" fontSize={2} fontWeight={2} target="_blank">
            DID Method
          </Link>{" "}
          defines a create, resolved, update, and deactivate (CRUD) model that describes how a specific DID scheme works
          with a specified distributed ledger or network. By selecting the DID Methods here, you’re allowing Serto Agent
          to create Decentralized Identifiers (DIDs) that use these method.
        </Text>
        {isValidating ? (
          <IsValidating />
        ) : error ? (
          <IsError error={error.toString()} />
        ) : (
          <DidMethodsForm didMethods={didMethods} />
        )}
      </Box>
    </>
  );
};

export interface DidMethodsFormProps {
  didMethods: DidMethodTypes;
}

export const DidMethodsForm: React.FunctionComponent<DidMethodsFormProps> = (props) => {
  const { toastProvider } = useContext(SertoUiContext);
  const SertoAgent = useContext(SertoAgentContext);
  const [error, setError] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [newDidMethods, setNewDidMethods] = useState<DidMethodTypes>(props.didMethods);

  const updateDidMethods = async (e: Event) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    if (Object.keys(newDidMethods).every((k) => !(newDidMethods as any)[k])) {
      setError("At least one DID Method is required");
      setLoading(false);
      return;
    }

    try {
      await SertoAgent.updateDidMethods(newDidMethods);
      mutate(SertoAgent.api.SETTINGS_DID_METHODS);
      toastProvider.addMessage("DID Methods successfully updated", {
        colorTheme: "light",
        variant: "success",
      });
    } catch (err) {
      console.error("failed to update methods:", err);
      setError(err.message);
    }

    setLoading(false);
  };

  return (
    <Form onSubmit={updateDidMethods} maxWidth="500px">
      <H4>Select the DID Methods your organization supports</H4>
      <FormField>
        {Object.entries(props.didMethods).map(([key, val]) => {
          if (key === "did:ethr") {
            return (
              <Fragment key={key}>
                <Checkbox
                  onChange={(event: any) => setNewDidMethods((inputs) => ({ ...inputs, [key]: event.target.checked }))}
                  defaultChecked={val}
                  key={key}
                  label="Ethereum DID (did:ethr)"
                />
                <Text mb={3} ml="29px" fontSize={0}>
                  This method allows any Ethereum smart contract or key pair account to become a decentralized
                  identifier (DID).{" "}
                  <Link
                    href="https://github.com/decentralized-identity/ethr-did-resolver/blob/master/doc/did-method-spec.md"
                    fontSize={0}
                    target="_blank"
                  >
                    Learn more about Ethr DID method here
                  </Link>
                </Text>
              </Fragment>
            );
          } else if (key === "did:ethr:rinkeby") {
            return (
              <Fragment key={key}>
                <Checkbox
                  onChange={(event: any) => setNewDidMethods((inputs) => ({ ...inputs, [key]: event.target.checked }))}
                  defaultChecked={val}
                  key={key}
                  label="Rinkeby Ethereum DID (did:ethr:rinkeby)"
                />
                <Text mb={3} ml="29px" fontSize={0}>
                  This method allows any Ethereum smart contract or key pair account deployed on the Rinkeby network to
                  become a decentralized identifier (DID).{" "}
                  <Link
                    href="https://github.com/decentralized-identity/ethr-did-resolver/blob/master/doc/did-method-spec.md"
                    fontSize={0}
                    target="_blank"
                  >
                    Learn more about Ethr Rinkeby DID method here
                  </Link>
                </Text>
              </Fragment>
            );
          } else if (key === "did:key") {
            return (
              <Fragment key={key}>
                <Checkbox
                  onChange={(event: any) => setNewDidMethods((inputs) => ({ ...inputs, [key]: event.target.checked }))}
                  defaultChecked={val}
                  key={key}
                  label="DID Key (did:key)"
                />
                <Text mb={3} ml="29px" fontSize={0}>
                  A ledger independent DID method based on public/private key pairs.{" "}
                  <Link href="https://w3c-ccg.github.io/did-method-key/" fontSize={0} target="_blank">
                    Learn more about DID key method here
                  </Link>
                </Text>
              </Fragment>
            );
          } else if (key === "did:web") {
            return (
              <Fragment key={key}>
                <Checkbox
                  onChange={(event: any) => setNewDidMethods((inputs) => ({ ...inputs, [key]: event.target.checked }))}
                  defaultChecked={val}
                  key={key}
                  label="DID Web (did:web)"
                />
                <Text mb={3} ml="29px" fontSize={0}>
                  This method lets your ownership of a domain and its DNS to become a decentralized identifier (DID). It
                  also supports DIDComm messaging, allowing DIDs to receive free messages and credentials.{" "}
                  <Link href="https://w3c-ccg.github.io/did-method-web/" fontSize={0} target="_blank">
                    Learn more about DID web method here
                  </Link>
                </Text>
              </Fragment>
            );
          }
        })}
      </FormField>
      {error && (
        <Flash mb={3} variant="danger">
          {error}
        </Flash>
      )}
      <Button disabled={loading} mt={4} type="submit">
        Save Changes
      </Button>
    </Form>
  );
};
