import { useContext } from "react";
import useSWR from "swr";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { Flex } from "rimble-ui";
import { baseColors, H5 } from "serto-ui";

export const OrgHeader: React.FunctionComponent = () => {
  const SertoAgent = useContext(SertoAgentContext);
  const { data } = useSWR("/v1/admin/organization", () => SertoAgent.getOrganization(), {
    revalidateOnFocus: false,
  });

  if (data?.name) {
    return (
      <Flex alignItems="center" bg={baseColors.white} borderRadius={1} height="72px" px={3} py={2}>
        <H5 my={0}>{data.name}</H5>
      </Flex>
    );
  }

  return <></>;
};
