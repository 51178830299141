import decodeJwt from "jwt-decode";
import { config } from "../config";
import { routes } from "../constants";
import { Identifier, VeramoIssueVcOptions } from "serto-ui";
import {
  AuthJwtTypes,
  DatabaseTypes,
  DidMethodTypes,
  SmtpServerTypes,
  UserCreateTypes,
  UserUpdateTypes,
} from "../types";

const AUTH_LOCALSTORAGE_KEY = `serto-agent-auth-${config.AGENT_API_URL}`;

export enum SertoAgentApiPath {
  AUTH = "/v1/auth/login/",
  AUTH_CHANGE_PASSWORD = "/v1/users/changePassword",
  AUTH_FORGOT_PASSWORD = "/v1/users/forgotPassword",
  ADMIN_USER = "/v1/admin/profile/",
  ADMIN_ORG = "/v1/admin/organization/",
  API_KEYS = "/v1/api-keys/",
  DID_CONFIG = "/v1/did-configuration/",
  SCHEMA_MRU = "/v1/schemas/mru/",
  SCHEMA_FAVORITES = "/v1/schemas/favorites/",
  SETTINGS_DATABASE_CONFIG = "/v1/settings/database_config/",
  SETTINGS_DID_METHODS = "/v1/settings/did_providers/",
  SETTINGS_ONBOARDING = "/v1/settings/onboarding/",
  SETTINGS_SMTP = "/v1/settings/smtp_server/",
  USERS = "/v1/users/",
  VERAMO_GET_CREDENTIALS = "/v1/agent/dataStoreORMGetVerifiableCredentials/",
  VERAMO_GET_MESSAGES = "/v1/agent/dataStoreORMGetMessages/",
  VERAMO_ISSUE_CREDENTIAL = "/v1/agent/createVerifiableCredential/",
  ENABLE_MESSAGING = "/v1/messaging/enable/",
  SEND_CREDENTIAL = "/v1/messaging/send/",
  VERAMO_GET_DID = "/v1/agent/didManagerGet/",
  VERAMO_GET_DIDS = "/v1/agent/didManagerFind/",
  VERAMO_CREATE_DID = "/v1/agent/didManagerCreate/",
  VERAMO_SET_DID_ALIAS = "/v1/agent/didManagerSetAlias/",
}

export interface Auth {
  jwt: string;
}

export class SertoAgentService {
  public loggingIn?: boolean;
  public auth?: Auth;
  public url = config.AGENT_API_URL;
  public api = SertoAgentApiPath;

  constructor() {
    this.loadAuthFromStorage();
  }

  // Auth
  public getAuth(): Auth | undefined {
    return this.auth;
  }

  public async login(username: string, password: string): Promise<any> {
    this.loggingIn = true;
    const jwt = await this.request(this.api.AUTH, "POST", { username, password }, false, true);
    this.setAuth({ jwt }, true);
    this.loggingIn = false;
    return true;
  }

  public logout(): void {
    this.clearAuth();
  }

  public isAuthenticated(): boolean {
    return !!this.auth && !this.loggingIn;
  }

  public resetPassword(jwt: string, newPassword: string): void {
    this.loggingIn = true;
    this.setAuth({ jwt }, true);
    this.loggingIn = false;
    this.changePassword(newPassword);
  }

  public async changePassword(newPassword: string): Promise<any> {
    return this.request(this.api.AUTH_CHANGE_PASSWORD, "POST", { newPassword });
  }

  public async forgotPassword(to: string): Promise<any> {
    return this.request(this.api.AUTH_FORGOT_PASSWORD, "POST", { to }, false, true);
  }

  // Admin
  public async getOrganization(): Promise<any> {
    return this.request(this.api.ADMIN_ORG);
  }

  public async getCurrentUser(): Promise<any> {
    return this.request(this.api.ADMIN_USER);
  }

  public getPermissions(): string {
    const token = localStorage.getItem(AUTH_LOCALSTORAGE_KEY);
    let role = "normal";

    if (token) {
      const decodedToken: AuthJwtTypes = decodeJwt(token);
      role = decodedToken.user.role;
    }

    return role;
  }

  // API Keys
  public async getApiKeys(): Promise<any> {
    return this.request(this.api.API_KEYS);
  }

  public async createApiKey(name: string): Promise<any> {
    return this.request(this.api.API_KEYS, "POST", { name });
  }

  public async deleteApiKey(name: string): Promise<any> {
    return this.request(`${this.api.API_KEYS}${name}`, "DELETE", {});
  }

  public async getReceivedCredentials(): Promise<any> {
    const messages = this.request(
      this.api.VERAMO_GET_MESSAGES,
      "POST",
      {
        where: [
          {
            column: "metaData",
            value: ['%"DIDComm-sent"%'],
            op: "Like",
            not: true,
          },
        ],
      },
      true,
    );
    return messages;
  }

  // Credentials
  public async getCredentials(): Promise<any> {
    return this.request(this.api.VERAMO_GET_CREDENTIALS, "POST", {}, true);
  }

  /* eslint-disable-next-line */
  public async issueVc(vc: any, options?: VeramoIssueVcOptions): Promise<any> {
    const body = {
      credential: vc,

      revocable: false,
      keepCopy: true,
      save: "true",
      proofFormat: "jwt",
      ...options,
    };
    return this.request(this.api.VERAMO_ISSUE_CREDENTIAL, "POST", body, true);
  }

  public async enableMessaging(did: string): Promise<any> {
    return this.request(`${this.api.ENABLE_MESSAGING}${did}`, "POST");
  }

  public async sendVc(from: string, to: string, vc: { [key: string]: any }): Promise<any> {
    return this.request(`${this.api.SEND_CREDENTIAL}${from}/${to}`, "POST", { content: JSON.stringify(vc) }, true);
  }

  // DIDs
  public async getDids(provider?: string): Promise<Identifier[]> {
    return this.request(this.api.VERAMO_GET_DIDS, "POST", { provider }, true);
  }

  public async getDid(did: string): Promise<any> {
    return this.request(this.api.VERAMO_GET_DID, "POST", { did }, true);
  }

  public async createDid(provider: string, alias?: string): Promise<any> {
    return this.request(this.api.VERAMO_CREATE_DID, "POST", { provider, alias }, true);
  }

  public async setDidAlias(did: string, alias: string): Promise<any> {
    return this.request(this.api.VERAMO_SET_DID_ALIAS, "POST", { did, alias }, true);
  }

  // DID Configs
  public async getAllDidConfigs(): Promise<any> {
    return this.request(this.api.DID_CONFIG);
  }

  public async getDidConfig(domain: string): Promise<any> {
    return this.request(`${this.api.DID_CONFIG}${domain}`);
  }

  public async createDidConfig(domain: string, dids: string[]): Promise<any> {
    return this.request(`${this.api.DID_CONFIG}${domain}`, "POST", { dids });
  }

  public async deleteDidConfig(domain: string): Promise<any> {
    return this.request(`${this.api.DID_CONFIG}${domain}`, "DELETE");
  }

  public async addDidToConfig(domain: string, did: string): Promise<any> {
    return this.request(`${this.api.DID_CONFIG}${domain}/${did}`, "POST");
  }

  public async deleteDidfromConfig(domain: string, did: string): Promise<any> {
    return this.request(`${this.api.DID_CONFIG}${domain}/${did}`, "DELETE");
  }

  public async refreshDidConfigStatus(domain: string): Promise<any> {
    return this.request(`${this.api.DID_CONFIG}refresh-status/${domain}`);
  }

  // Schemas
  public async getSchemaMru(): Promise<any> {
    return this.request(this.api.SCHEMA_MRU);
  }

  public async getSchemaFavorite(): Promise<any> {
    return this.request(this.api.SCHEMA_FAVORITES);
  }

  public async addSchemaFavorite(uuid: string): Promise<any> {
    return this.request(this.api.SCHEMA_FAVORITES, "POST", { uuid });
  }

  public async deleteSchemaFavorite(uuid: string): Promise<any> {
    return this.request(this.api.SCHEMA_FAVORITES, "DELETE", { uuid });
  }

  // Settings
  public async getDatabase(): Promise<any> {
    return this.request(this.api.SETTINGS_DATABASE_CONFIG);
  }

  public async updateDatabase(data: DatabaseTypes): Promise<any> {
    return this.request(this.api.SETTINGS_DATABASE_CONFIG, "POST", data);
  }

  public async getDidMethods(): Promise<any> {
    return this.request(this.api.SETTINGS_DID_METHODS);
  }

  public async updateDidMethods(data: DidMethodTypes): Promise<any> {
    return this.request(this.api.SETTINGS_DID_METHODS, "POST", data);
  }

  public async getSmtpServer(): Promise<any> {
    return this.request(this.api.SETTINGS_SMTP);
  }

  public async updateSmtpServer(data: SmtpServerTypes): Promise<any> {
    return this.request(this.api.SETTINGS_SMTP, "POST", data);
  }

  public async getOnboarding(): Promise<any> {
    return this.request(this.api.SETTINGS_ONBOARDING);
  }

  // Users Management
  public async getUsers(): Promise<any> {
    return this.request(this.api.USERS);
  }

  public async createUser(data: UserCreateTypes): Promise<any> {
    return this.request(this.api.USERS, "POST", data);
  }

  public async updateUser(data: UserUpdateTypes): Promise<any> {
    return this.request(this.api.USERS, "PUT", data);
  }

  public async getUser(id: string): Promise<any> {
    return this.request(`${this.api.USERS}${id}`);
  }

  public async deleteUser(id: string): Promise<any> {
    return this.request(`${this.api.USERS}${id}`, "DELETE");
  }

  private async request(
    path: string,
    method: "GET" | "DELETE" | "PUT" | "POST" = "GET",
    body?: any,
    acceptHeader?: boolean,
    unauthenticated?: boolean,
  ): Promise<any> {
    if (!unauthenticated) {
      this.ensureAuthenticated();
    }
    const headers: any = {};
    if (this.auth?.jwt) {
      headers.Authorization = `Bearer ${this.auth.jwt}`;
    }
    if (body) {
      headers["Content-Type"] = "application/json";
    }
    if (acceptHeader) {
      headers["Accept"] = "application/json";
    }

    const response = await fetch(`${this.url}${path}`, {
      method,
      headers,
      body: JSON.stringify(body),
    });
    const responseIsJson = response.headers.get("content-type")?.indexOf("application/json") === 0;

    if (!response.ok) {
      if (response.status === 401 && !this.loggingIn) {
        this.logout();
      }

      let errorMessage;
      if (responseIsJson) {
        const errorJson = await response.json();
        if (errorJson?.error?.message) {
          errorMessage = errorJson.error.message;
          if (errorJson.error.code) {
            errorMessage += ` (${errorJson.error.code})`;
          }
        } else {
          errorMessage = JSON.stringify(errorJson);
        }
      } else {
        errorMessage = await response.text();
      }
      console.error("API error", response.status, errorMessage);
      throw new Error("API error: " + errorMessage);
    }

    if (responseIsJson) {
      try {
        return await response.json();
      } catch (err) {
        if (response.headers.get("content-length") === "0") {
          throw new Error('API error: API returned invalid JSON: ""');
        }
        throw err;
      }
    } else {
      return await response.text();
    }
  }

  private setAuth(auth: Auth, persist?: boolean) {
    this.auth = auth;

    if (persist) {
      localStorage.setItem(AUTH_LOCALSTORAGE_KEY, JSON.stringify(auth));
    }
  }

  private clearAuth() {
    window.location.href = routes.LOGIN;
    delete this.auth;
    localStorage.removeItem(AUTH_LOCALSTORAGE_KEY);
  }

  private ensureAuthenticated() {
    if (!this.auth) {
      throw new Error("not authenticated");
    }
  }

  private loadAuthFromStorage(): void {
    const authString = localStorage.getItem(AUTH_LOCALSTORAGE_KEY);

    if (authString === "" || authString === null) {
      return;
    }

    try {
      const auth = JSON.parse(authString);
      this.auth = auth;
    } catch (err) {
      console.error("failed to parse auth", authString);
    }
  }
}
