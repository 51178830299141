import { useContext, useState } from "react";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { Button, Field, Flash, Form, Input, Text } from "rimble-ui";
import { H3 } from "serto-ui";
import { IsSuccess, LoginLayout } from "../../components";
import { ErrorMsgAuth } from "../../components/text";

export const ForgotPasswordPage: React.FunctionComponent = () => {
  const SertoAgent = useContext(SertoAgentContext);
  const [error, setError] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>();
  const [email, setEmail] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);

  async function forgotPassword(e: Event) {
    e.preventDefault();
    setError(false);
    setLoading(true);
    try {
      await SertoAgent.forgotPassword(email);
      setSuccess(true);
    } catch (err) {
      console.error("error sending link:", err);
      setError(<ErrorMsgAuth error={err.message} />);
    }
    setLoading(false);
  }

  return (
    <LoginLayout>
      {success ? (
        <IsSuccess text="Email Sent!">
          <Text fontSize={2} mb={2}>
            An email has been sent to {email} with instructions on resetting your password.
          </Text>
        </IsSuccess>
      ) : (
        <>
          <H3 mb={3} mt={0}>
            Forgot Password?
          </H3>
          <Text fontSize={2} mb={3}>
            Enter the email associated with your Agent account.
          </Text>
          <Form onSubmit={forgotPassword} width="100%">
            <Field width="100%" label="Email Address">
              <Input
                placeholder="user@example.com"
                type="email"
                width="100%"
                required
                onChange={(e: any) => setEmail(e.target.value)}
              />
            </Field>
            {error && (
              <Flash mb={3} variant="danger">
                {error}
              </Flash>
            )}
            <Button disabled={loading} type="submit" width="100%">
              Send Link
            </Button>
          </Form>
        </>
      )}
    </LoginLayout>
  );
};
