import { routes, sections } from "../../constants";
import { Profile } from "./Profile";
import { Header, HeaderBox } from "serto-ui";
import { ContentBox, Global } from "../../components";

export const ProfilePage: React.FunctionComponent = () => {
  return (
    <Global section={sections.SETTINGS} url={routes.SETTINGS_PROFILE}>
      <HeaderBox>
        <Header heading="Settings" />
      </HeaderBox>
      <ContentBox>
        <Profile />
      </ContentBox>
    </Global>
  );
};
