import { useContext } from "react";
import useSWR from "swr";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { Box, Text } from "rimble-ui";
import { colors } from "serto-ui";
import { IsValidating, SecondaryHeader } from "../../components";
import { EmailServerForm } from "./EmailServerForm";

export const EmailServer: React.FunctionComponent = () => {
  const SertoAgent = useContext(SertoAgentContext);
  const { data: smtp, isValidating } = useSWR(SertoAgent.api.SETTINGS_SMTP, () => SertoAgent.getSmtpServer(), {
    revalidateOnFocus: false,
  });

  return (
    <>
      <SecondaryHeader heading="Set Up SMTP Server Settings">
        <Text color={colors.silver}>
          To send and receive notification emails, set up Serto Agent to send SMTP Mail.
        </Text>
      </SecondaryHeader>
      {isValidating ? (
        <IsValidating />
      ) : (
        <Box borderTop={2} p={4}>
          <Text mb={3}>Use the SMTP details provided by your hosting provider or email service.</Text>
          <EmailServerForm smtp={smtp} />
        </Box>
      )}
    </>
  );
};
