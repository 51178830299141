import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { routes, sections } from "../../constants";
import { APIKeyManagement } from "./APIKeyManagement";
import { Header, HeaderBox } from "serto-ui";
import { ContentBox, Global } from "../../components";

export const APIKeyManagementPage: React.FunctionComponent = () => {
  const SertoAgent = useContext(SertoAgentContext);
  const hasPermissions = SertoAgent.getPermissions() === "admin";
  const history = useHistory();

  if (!hasPermissions) {
    history.push(routes.SETTINGS_PROFILE);
  }

  return (
    <Global section={sections.SETTINGS} url={routes.SETTINGS_API}>
      <HeaderBox>
        <Header heading="Settings" />
      </HeaderBox>
      <ContentBox>
        <APIKeyManagement />
      </ContentBox>
    </Global>
  );
};
