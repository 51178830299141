import { useState } from "react";
import { GenerateDID } from "./GenerateDID";
import { GenerateDIDSuccess } from "./GenerateDIDSuccess";
import { OrgProfile } from "./OrgProfile";
import { OrgProfileSuccess } from "./OrgProfileSuccess";
import { GenerateDIDConfig } from "./GenerateDIDConfig";
import { DownloadDIDConfig } from "./DownloadDIDConfig";
import { MakeOrgSearchable } from "./MakeOrgSearchable";
import { Box, Flex, Text } from "rimble-ui";
import { baseColors } from "serto-ui";
import { IsSuccess } from "../../../components";
import { awsRegex, ipAddressRegex } from "../../../constants";
import { OnboardingTypes } from "../../../types";

export const ONBOARDING_STEPS = [
  "GENERATE_DID",
  "GENERATE_DID_SUCCESS",
  "ORG_PROFILE",
  "ORG_PROFILE_SUCCESS",
  "GENERATE_DID_CONFIG",
  "DOWNLOAD_DID_CONFIG",
  "MAKE_ORG_SEARCHABLE",
  "COMPLETE",
];

export enum ONBOARDING_FLOWS {
  GENERIC_DOMAIN = "generic domain",
  CUSTOM_DOMAIN_DNS = "custom domain dns",
  ETH = "eth",
}

/*
// For testing individual components
const testDid = {
  alias: "tests ethr",
  controllerKeyId:
    "04b3f13c47071a51b76115addd89ea0adcb7b4786ee3bcf2369faa01bbcad1610568260175080b87084d4aff10ebb5bd068556f7d8dbb218e1d6aa7a9b6d9bfdd4",
  did: "did:ethr:0x02b3f13c47071a51b76115addd89ea0adcb7b4786ee3bcf2369faa01bbcad16105",
  provider: "did:ethr",
  services: [],
};
const testDomain = "serto.id";
const testOnboardingFlow = ONBOARDING_FLOWS.ETH;
const testOrgProfileVc = "eyJhbGciOiJFUzI1NksiLCJ0eXAiOiJKV1QifQ.eyJ2YyI6eyJjcmVkZW50aWFsU3ViamVjdCI6eyJkb21haW4iOiJzZXJ0by5pZCIsIm5hbWUiOiJzZXJ0byIsImRlc2NyaXB0aW9uIjoidGVzdCIsImxvZ29VcmwiOiJodHRwOi8vYmV0YS5zZWFyY2guc2VydG8uaWQvZmF2aWNvbi5pY28ifSwiQGNvbnRleHQiOlsiaHR0cHM6Ly93d3cudzMub3JnLzIwMTgvY3JlZGVudGlhbHMvdjEiLCJodHRwOi8vc3RhZ2luZy5hcGkuc2VhcmNoLnNlcnRvLmlkL3YxL29yZy1wcm9maWxlLXNjaGVtYS1wcmVmaXhsZC1jb250ZXh0Lmpzb24iXSwidHlwZSI6WyJWZXJpZmlhYmxlQ3JlZGVudGlhbCIsIlNlcnRvT3JnYW5pemF0aW9uUHJvZmlsZSJdfSwiY3JlZGVudGlhbFNjaGVtYSI6eyJpZCI6Imh0dHA6Ly9zdGFnaW5nLmFwaS5zZWFyY2guc2VydG8uaWQvdjEvb3JnLXByb2ZpbGUtc2NoZW1hLXByZWZpeGpzb24tc2NoZW1hLmpzb24iLCJ0eXBlIjoiSnNvblNjaGVtYVZhbGlkYXRvcjIwMTgifSwibmJmIjoxNjI1MTY0NjQwLCJpc3MiOiJkaWQ6d2ViOmxvY2FsaG9zdCJ9.K88FeoLMHI7ZKVNDUFbpvnk0TH2N9rqkWLTr8SZ0CnpuuqI6bFNJmsVLXOVVSI3Bm4y9NF3AHRx0PfPB_KR6XQ";
*/

export interface OnboardingProps {
  onboarding: OnboardingTypes;
  onComplete(): void;
}

export const Onboarding: React.FunctionComponent<OnboardingProps> = (props) => {
  const { onboarding } = props;
  const onboardingStarted = Object.keys(onboarding).length > 0;
  const currentDomain = window.location.hostname;
  const hasDnsSetup =
    !awsRegex.test(currentDomain) && !ipAddressRegex.test(currentDomain) && !currentDomain.includes("localhost");
  const isDidWeb = onboarding.did?.includes("did:web");
  const savedFlow =
    !onboardingStarted || !onboarding.did
      ? ""
      : !isDidWeb
      ? ONBOARDING_FLOWS.ETH
      : isDidWeb && hasDnsSetup
      ? ONBOARDING_FLOWS.CUSTOM_DOMAIN_DNS
      : ONBOARDING_FLOWS.GENERIC_DOMAIN;
  const savedStep = !onboardingStarted
    ? ONBOARDING_STEPS[0]
    : onboarding.did && !onboarding.orgVc
    ? ONBOARDING_STEPS[2]
    : onboarding.did && onboarding.orgVc
    ? ONBOARDING_STEPS[4]
    : ONBOARDING_STEPS[0];
  const savedDomain = onboardingStarted && onboarding.orgVc ? onboarding.orgVc.credentialSubject.domain : "";

  const [step, setStep] = useState<string>(savedStep);
  const [did, setDid] = useState<any>(onboarding.did || {});
  const [domain, setDomain] = useState<string>(savedDomain);
  const [onboardingFlow, setOnboardingFlow] = useState<string>(savedFlow);
  const [orgProfileVc, setOrgProfileVc] = useState<any>({});

  return (
    <>
      {step === "GENERATE_DID" ? (
        <GenerateDID
          onComplete={(did, onboardingFlow) => {
            setOnboardingFlow(onboardingFlow);
            setDid(did);
            setStep(ONBOARDING_STEPS[1]);
          }}
        />
      ) : step === "GENERATE_DID_SUCCESS" ? (
        <GenerateDIDSuccess
          did={did}
          onboardingFlow={onboardingFlow}
          onComplete={() => {
            setStep(ONBOARDING_STEPS[2]);
          }}
        />
      ) : step === "ORG_PROFILE" ? (
        <OrgProfile
          did={did}
          onboardingFlow={onboardingFlow}
          onComplete={(vc, domain) => {
            setOrgProfileVc(vc);
            setDomain(domain);
            setStep(ONBOARDING_STEPS[3]);
          }}
        />
      ) : step === "ORG_PROFILE_SUCCESS" ? (
        <OrgProfileSuccess
          onboardingFlow={onboardingFlow}
          vc={orgProfileVc}
          onComplete={(nextStep) => {
            setStep(nextStep);
          }}
        />
      ) : step === "GENERATE_DID_CONFIG" ? (
        <GenerateDIDConfig
          did={did}
          domain={domain}
          onboardingFlow={onboardingFlow}
          onComplete={() => setStep(ONBOARDING_STEPS[5])}
        />
      ) : step === "DOWNLOAD_DID_CONFIG" ? (
        <DownloadDIDConfig
          domain={domain}
          onboardingFlow={onboardingFlow}
          onComplete={() => {
            setStep(ONBOARDING_STEPS[6]);
          }}
        />
      ) : step === "MAKE_ORG_SEARCHABLE" ? (
        <MakeOrgSearchable
          domain={domain}
          onComplete={() => {
            setStep(ONBOARDING_STEPS[7]);
          }}
        />
      ) : (
        <Flex bg={baseColors.white} borderRadius={1} flexGrow="1" justifyContent="center" p={5}>
          <Box mt={6}>
            <IsSuccess text="Setup Complete!" onClick={() => props.onComplete()}>
              <Text fontSize={2} fontWeight={3} textAlign="center">
                Your Agent is now fully set up
              </Text>
            </IsSuccess>
          </Box>
        </Flex>
      )}
    </>
  );
};
