import { useContext, useState } from "react";
import { mutate } from "swr";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { Box, Button, Field, Flash, Form, Input } from "rimble-ui";
import { ModalContent, ModalHeader } from "serto-ui";
import { DidMethodDropDown, FormField, FormLabel } from "../../components/elements";
import {
  AliasExplainerText,
  DIDWebDomainExplainerText,
  ErrorMsgIdentifiers,
  ErrorMsgIdentifiersInvalidDomain,
  RequiredField,
} from "../../components/text";
import { config } from "../../config";

export interface DIDManagementCreateFormProps {
  didMethods: string[];
  close(): void;
}

export const DIDManagementCreateForm: React.FunctionComponent<DIDManagementCreateFormProps> = (props) => {
  const SertoAgent = useContext(SertoAgentContext);
  const [alias, setAlias] = useState<string>("");
  const [didMethod, setDidMethod] = useState<string>(props.didMethods[0]);
  const [error, setError] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const agentDomain = config.AGENT_API_URL.replace(/https?:\/\//, "");

  async function createIdentifier(e: Event) {
    e.preventDefault();

    if (didMethod === "did:web" && alias.includes("://")) {
      setError(<ErrorMsgIdentifiersInvalidDomain />);
      return;
    }

    setError("");
    setLoading(true);
    try {
      await SertoAgent.createDid(didMethod, alias);

      if (didMethod === "did:web") {
        try {
          await SertoAgent.enableMessaging(`did:web:${alias}`);
        } catch (err) {
          console.error("Identifier created successfully, but failed to enable messaging:", err);
        }
      }

      await mutate(SertoAgent.api.VERAMO_GET_DIDS);
      props.close();
      return;
    } catch (err) {
      console.error("failed to create identifier:", err);
      setError(<ErrorMsgIdentifiers error={err.message} />);
    }
    setLoading(false);
  }

  return (
    <Form onSubmit={createIdentifier}>
      <RequiredField />
      <Box mb={3}>
        <FormField>
          <FormLabel>Did Provider *</FormLabel>
          <DidMethodDropDown didMethods={props.didMethods} onChange={(value: string) => setDidMethod(value)} />
        </FormField>
        {didMethod === "did:web" ? (
          <>
            <Field label="Domain *" mb={0} width="100%">
              <Input
                type="text"
                placeholder="agent.yourdomain.com"
                onChange={(e: any) => setAlias(e.target.value)}
                required
                width="100%"
              />
            </Field>
            <DIDWebDomainExplainerText domain={alias} agentDomain={agentDomain} />
          </>
        ) : (
          <>
            <Field label="Alias *" mb={0} width="100%">
              <Input
                type="text"
                placeholder="Ex: “My Primary DID”"
                onChange={(e: any) => setAlias(e.target.value)}
                required
                width="100%"
              />
            </Field>
            <AliasExplainerText />
          </>
        )}
      </Box>
      {error && (
        <Flash mb={3} variant="danger">
          {error}
        </Flash>
      )}
      <Button disabled={loading} type="submit" width="100%">
        Create DID
      </Button>
    </Form>
  );
};

export interface DIDManagementCreateProps {
  didMethods: { [key: string]: boolean };
  close(): void;
}

export const DIDManagementCreate: React.FunctionComponent<DIDManagementCreateProps> = (props) => {
  const didMethodsOptions: any = [];

  if (props.didMethods) {
    Object.entries(props.didMethods).forEach(([key, val]) => {
      if (val === true) {
        didMethodsOptions.push(key);
      }
    });
  }

  return (
    <Box>
      <ModalHeader>Create DID</ModalHeader>
      <ModalContent>
        {didMethodsOptions.length && <DIDManagementCreateForm close={props.close} didMethods={didMethodsOptions} />}
      </ModalContent>
    </Box>
  );
};
