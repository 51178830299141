import { Box, Flex, Text } from "rimble-ui";
import { baseColors, colors, H3 } from "serto-ui";
import { OnboardingChecklist } from "../elements";

export interface OnboardingWidgetProps {
  onboardingFlow?: string;
  step: number;
  stepTitleNumber: number;
  title: string | JSX.Element;
}

export const OnboardingWidget: React.FunctionComponent<OnboardingWidgetProps> = (props) => {
  return (
    <Flex bg={baseColors.white} borderRadius={1} flexGrow="1" justifyContent="space-between" pb={6} pt={3} px={4}>
      <Box width="calc(100% - 300px)">
        <H3 mb={2} mt={4}>
          <Box position="relative" pl="35px">
            <Box position="absolute" left="0" top="2px">
              <OnboardingStep step={props.stepTitleNumber} />
            </Box>
            {props.title}
          </Box>
        </H3>
        <Box mr={4}>{props.children}</Box>
      </Box>
      <Box mt={4} width="300px">
        <OnboardingChecklist onboardingFlow={props.onboardingFlow} step={props.step} />
      </Box>
    </Flex>
  );
};

export interface OnboardingWidgetDescriptionProps {
  mb?: string | number;
}

export const OnboardingWidgetDescription: React.FunctionComponent<OnboardingWidgetDescriptionProps> = (props) => {
  return (
    <Text colors={colors.midGray} fontSize={1} mb={props.mb || 5}>
      {props.children}
    </Text>
  );
};

export interface OnboardingStepProps {
  step: number;
}

export const OnboardingStep: React.FunctionComponent<OnboardingStepProps> = (props) => {
  return (
    <Flex
      alignItems="center"
      border={"2px solid " + colors.primary.base}
      borderRadius="50%"
      height="26px"
      justifyContent="center"
      mr={2}
      width="26px"
    >
      <Text color={colors.primary.base} fontSize={2} fontWeight="700">
        {props.step}
      </Text>
    </Flex>
  );
};
