import { useContext, useState } from "react";
import { mutate } from "swr";
import { UserCreateTypes } from "../../types";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { Box, Button, Field, Flash, Form, Input, Loader, Text } from "rimble-ui";
import { baseColors, colors, DropDown, ModalContent, ModalHeader } from "serto-ui";
import { ErrorMsgMemberManagement, FormField, FormLabel, IsSuccess } from "../../components";
import { generatePassword } from "../../utils/generatePassword";

export interface MemberAddProps {
  close(): void;
}

export const MemberAdd: React.FunctionComponent<MemberAddProps> = (props) => {
  const SertoAgent = useContext(SertoAgentContext);
  const password = generatePassword();
  const [error, setError] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<UserCreateTypes>({ username: "", password, name: "", role: "admin" });
  const [userAdded, setUserAdded] = useState<boolean>(false);

  const createMember = async (e: Event) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    if (!user.name || !user.username || !user.password || !user.role) {
      setError("All fields are required.");
    } else if (user.password.length < 8) {
      setError("Minimum 8 characters required for password.");
    } else {
      try {
        await SertoAgent.createUser(user);
        mutate(SertoAgent.api.USERS);
        setUserAdded(true);
      } catch (err) {
        console.error("failed to add user:", err);
        setError(<ErrorMsgMemberManagement error={err.message} />);
      }
    }

    setLoading(false);
  };

  if (!userAdded) {
    return (
      <>
        <ModalHeader>Add Member</ModalHeader>
        <ModalContent>
          <Form onSubmit={createMember}>
            <Field width="100%" label="Full Name">
              <Input
                type="text"
                width="100%"
                required
                onChange={(e: any) => setUser((inputs) => ({ ...inputs, name: e.target.value }))}
              />
            </Field>
            <Field width="100%" label="Email">
              <Input
                type="text"
                width="100%"
                required
                onChange={(e: any) => setUser((inputs) => ({ ...inputs, username: e.target.value }))}
              />
            </Field>
            <FormField>
              <FormLabel>
                Password{" "}
                <Text.span color={colors.silver} fontSize={1} fontStyle="italic">
                  (Minimum 8 characters)
                </Text.span>
              </FormLabel>
              <Input
                type="text"
                width="100%"
                required
                onChange={(e: any) => setUser((inputs) => ({ ...inputs, password: e.target.value }))}
                value={user.password}
              />
            </FormField>
            <Box mb={4}>
              <Text fontSize={1} fontWeight={3} mb={2}>
                Role
              </Text>
              <DropDown
                arrowColor={colors.primary.base}
                defaultSelectedValue={user.role}
                onChange={(value: string) => setUser((inputs) => ({ ...inputs, role: value }))}
                options={[
                  { value: "admin", name: "Admin" },
                  { value: "normal", name: "User" },
                ]}
              />
            </Box>
            {error && (
              <Flash mb={3} variant="danger">
                {error}
              </Flash>
            )}
            <Button disabled={loading} type="submit" width="100%">
              {loading ? <Loader color={baseColors.white} /> : "Add Member"}
            </Button>
          </Form>
        </ModalContent>
      </>
    );
  }

  return (
    <>
      <ModalContent>
        <IsSuccess onClick={props.close} text={user.name + " has been added!"}>
          <Flash variant="warning">
            Please notify <b>{user.username}</b> that their account has been set up and include their password{" "}
            <b>{user.password}</b>
          </Flash>
        </IsSuccess>
      </ModalContent>
    </>
  );
};
