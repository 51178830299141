import { useContext, useState } from "react";
import { SertoAgentContext } from "../../../context/SertoAgentProvider";
import { CheckCircle, Warning } from "@rimble/icons";
import { Box, Button, Flash, Flex, Form, Input, Link, Text } from "rimble-ui";
import { colors, H5, Identifier } from "serto-ui";
import {
  DidMethodDropDown,
  ErrorMsgIdentifiers,
  FormField,
  FormHelperText,
  FormLabel,
  OnboardingWidget,
  OnboardingWidgetDescription,
} from "../../../components";
import { awsRegex, ipAddressRegex, links } from "../../../constants";
import { ONBOARDING_FLOWS } from "./Onboarding";

export interface GenerateDIDProps {
  onComplete(did: Identifier, onboardingFlow: string): void;
}

export const GenerateDID: React.FunctionComponent<GenerateDIDProps> = (props) => {
  const SertoAgent = useContext(SertoAgentContext);
  const currentDomain = window.location.hostname;
  const hasDnsSetup =
    !awsRegex.test(currentDomain) && !ipAddressRegex.test(currentDomain) && !currentDomain.includes("localhost");
  const didMethods = ["did:web", "did:ethr:rinkeby", "did:ethr", "did:key"];
  const [alias, setAlias] = useState<string>("");
  const [didMethod, setDidMethod] = useState<string>("did:web");
  const [error, setError] = useState<any | undefined>();
  const [currentOnboardingFlow, setCurrentOnboardingFlow] = useState<string>(
    hasDnsSetup ? ONBOARDING_FLOWS.CUSTOM_DOMAIN_DNS : ONBOARDING_FLOWS.GENERIC_DOMAIN,
  );

  async function createIdentifier(e: Event) {
    e.preventDefault();
    const isDidWeb = didMethod === "did:web";
    setError("");

    if (!isDidWeb && !alias) {
      setError("Alias is required");
      return;
    }

    const didAlias = didMethod === "did:web" ? currentDomain : alias;

    try {
      const did = await SertoAgent.createDid(didMethod, didAlias);
      if (didMethod === "did:web") {
        try {
          await SertoAgent.enableMessaging(`did:web:${alias}`);
        } catch (err) {
          console.error("Identifier created successfully, but failed to enable messaging:", err);
        }
      }
      props.onComplete(did, currentOnboardingFlow);
    } catch (err) {
      console.error("failed to create identifier:", err);
      setError(<ErrorMsgIdentifiers error={err.message} />);
      return;
    }
  }

  return (
    <OnboardingWidget
      onboardingFlow={currentOnboardingFlow}
      step={0}
      stepTitleNumber={1}
      title="Create a decentralized identifier (DID)"
    >
      <OnboardingWidgetDescription>
        You can create more identifiers later.{" "}
        <Link href={links.DOC_DIDS} target="_blank">
          Learn more about DIDs.
        </Link>
      </OnboardingWidgetDescription>
      <Form maxWidth="620px" onSubmit={createIdentifier}>
        <Box mb={5}>
          <FormField maxWidth="424px">
            <FormLabel>DID Method</FormLabel>
            <DidMethodDropDown
              didMethods={didMethods}
              onChange={(value: string) => {
                setDidMethod(value);
                value === "did:web" && hasDnsSetup
                  ? setCurrentOnboardingFlow(ONBOARDING_FLOWS.CUSTOM_DOMAIN_DNS)
                  : value === "did:web" && !hasDnsSetup
                  ? setCurrentOnboardingFlow(ONBOARDING_FLOWS.GENERIC_DOMAIN)
                  : setCurrentOnboardingFlow(ONBOARDING_FLOWS.ETH);
              }}
              showRecommended={true}
            />
            {didMethod === "did:web" ? (
              <Flex alignItems="center" mb={4} ml={3} mt={3}>
                <CheckCircle color={colors.success.base} mr={1} size="18px" />
                <Text.span color={colors.success.dark}>Messaging Enabled</Text.span>
              </Flex>
            ) : (
              <Flex maxWidth="424px" mb={4} ml={3} mt={3}>
                <Warning color={colors.warning.base} />
                <Text color={colors.warning.dark} ml={2}>
                  We don’t currently support receiving messages or credentials via the Ethereum or Key DID methods. To
                  receive messages and credentials for free, use the DID Web method.
                </Text>
              </Flex>
            )}
          </FormField>
          {didMethod === "did:web" ? (
            <>
              <FormField>
                <FormLabel>Your Agent's DID Preview</FormLabel>
                <Flex
                  alignItems="center"
                  bg={colors.nearWhite}
                  borderRadius={1}
                  height="82px"
                  maxWidth="620px"
                  px={3}
                  width="100%"
                >
                  <H5>{"did:web:" + currentDomain}</H5>
                </Flex>
                {!hasDnsSetup && (
                  <FormHelperText>
                    Use this Agent’s DID for testing only. For production, we recommend{" "}
                    <Link href={links.DOC_DNS_SETUP} target="_blank">
                      setting up your DNS
                    </Link>{" "}
                    for this Agent before creating a DID. Or, you can link this DID to your organization’s domain later.
                  </FormHelperText>
                )}
              </FormField>
            </>
          ) : (
            <FormField maxWidth="424px">
              <FormLabel>Alias</FormLabel>
              <Input
                onChange={(e: any) => setAlias(e.target.value)}
                placeholder="Ex: “My Primary DID”"
                required
                type="text"
                width="100%"
              />
              <FormHelperText>Aliases can be edited at any time</FormHelperText>
            </FormField>
          )}
          {error && (
            <Flash maxWidth="424px" mb={3} variant="danger">
              {error}
            </Flash>
          )}
        </Box>
        <Button maxWidth="424px" type="submit" width="100%">
          Create Decentralized Identifier (DID)
        </Button>
      </Form>
    </OnboardingWidget>
  );
};
