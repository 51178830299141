import { routes, sections } from "../../../constants";
import { Search } from "./Search";
import { Header, HeaderBox } from "serto-ui";
import { ContentBox, Global } from "../../../components";

export const SearchPage: React.FunctionComponent = () => {
  return (
    <Global section={sections.IDENTIFIERS} url={routes.IDENTIFIERS_SEARCH}>
      <HeaderBox>
        <Header heading="Identifiers" />
      </HeaderBox>
      <ContentBox>
        <Search />
      </ContentBox>
    </Global>
  );
};
