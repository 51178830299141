import { useContext } from "react";
import { Table, Text } from "rimble-ui";
import { colors, dateTimeFormat, Credential, SecondaryHeader, THead, TH, TR } from "serto-ui";
import useSWR from "swr";
import {
  IsError,
  IsValidating,
  NoData,
  NoDIDCredentialsText,
  NoIssuedCredentialsText,
  TBodyCredentials,
  TDCredentials,
} from "../../components";
import { SertoAgentContext } from "../../context/SertoAgentProvider";

export interface CredentialsProps {
  identifiers: boolean;
  issued: boolean;
}

export const IssuedCredentials: React.FunctionComponent<CredentialsProps> = (props) => {
  const SertoAgent = useContext(SertoAgentContext);
  const { data: credentials, error, isValidating } = useSWR(
    SertoAgent.api.VERAMO_GET_CREDENTIALS,
    () => SertoAgent.getCredentials(),
    {
      revalidateOnFocus: false,
    },
  );

  return (
    <>
      <SecondaryHeader heading="Issued">
        <Text color={colors.silver}>The collection of credentials your organization has issued.</Text>
      </SecondaryHeader>
      {!props.identifiers ? (
        <NoData text={<NoDIDCredentialsText />} />
      ) : isValidating ? (
        <IsValidating />
      ) : error ? (
        <IsError error={"Error loading credentials: " + error.toString()} />
      ) : credentials?.length ? (
        <Table border={0} boxShadow={0} width="100%">
          <THead>
            <TR>
              <TH>Date</TH>
              <TH>Credential</TH>
            </TR>
          </THead>
          <TBodyCredentials>
            {credentials
              .map((vc: any, i: number) => {
                const receivedDate = dateTimeFormat(new Date(vc.verifiableCredential.issuanceDate));
                return (
                  <TR key={i}>
                    <TDCredentials style={{ verticalAlign: "top", width: "200px" }}>
                      <Text>{receivedDate.dateFormatted}</Text>
                      <Text color={colors.silver} fontSize={0}>
                        {receivedDate.timeFormatted}
                      </Text>
                    </TDCredentials>
                    <TDCredentials>
                      <Credential key={i} vc={vc.verifiableCredential} />
                    </TDCredentials>
                  </TR>
                );
              })
              .reverse()}
          </TBodyCredentials>
        </Table>
      ) : (
        <NoData text={<NoIssuedCredentialsText />} />
      )}
    </>
  );
};
