import generator from "generate-password";

export const generatePassword = (): string => {
  const pwd = generator.generate({
    length: 20,
    lowercase: true,
    uppercase: true,
    numbers: true,
    symbols: true,
  });
  return pwd;
};
