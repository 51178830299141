import { useContext, Fragment } from "react";
import useSWR from "swr";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { Table, Text } from "rimble-ui";
import { colors, dateTimeFormat, Credential, SecondaryHeader, THead, TH, TR } from "serto-ui";
import {
  IsError,
  IsValidating,
  NoReceivedCredentialsText,
  NoData,
  TBodyCredentials,
  TDCredentials,
} from "../../components";

export const ReceivedCredentials: React.FunctionComponent = () => {
  const SertoAgent = useContext(SertoAgentContext);
  const { data: credentials, error, isValidating } = useSWR(
    SertoAgent.api.VERAMO_GET_MESSAGES,
    () => SertoAgent.getReceivedCredentials(),
    {
      revalidateOnFocus: false,
    },
  );

  return (
    <>
      <SecondaryHeader heading="Received">
        <Text color={colors.silver}>The collection of credentials your organization has received.</Text>
      </SecondaryHeader>
      {isValidating ? (
        <IsValidating />
      ) : error ? (
        <IsError error={"Error loading credentials: " + error.toString()} />
      ) : credentials?.length ? (
        <Table border={0} boxShadow={0} width="100%">
          <THead>
            <TR>
              <TH>Date</TH>
              <TH>Credential</TH>
            </TR>
          </THead>
          <TBodyCredentials>
            {credentials
              .map((vc: any, i: number) => {
                const receivedDate = dateTimeFormat(new Date(vc.createdAt));
                // Only show didcomm messages for now
                const isDidcomm = vc.metaData.some((el: any) => el.type === "DIDComm");
                if (!isDidcomm) {
                  return <Fragment key={i}></Fragment>;
                }
                return (
                  <TR key={i}>
                    <TDCredentials style={{ verticalAlign: "top", width: "200px" }}>
                      <Text>{receivedDate.dateFormatted}</Text>
                      <Text color={colors.silver} fontSize={0}>
                        {receivedDate.timeFormatted}
                      </Text>
                    </TDCredentials>
                    <TDCredentials>
                      <Credential key={i} vc={vc.credentials[0]} />
                    </TDCredentials>
                  </TR>
                );
              })
              .reverse()}
          </TBodyCredentials>
        </Table>
      ) : (
        <NoData text={<NoReceivedCredentialsText />} />
      )}
    </>
  );
};
