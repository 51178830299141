import { useContext, useState } from "react";
import { UserUpdateTypes } from "../../types";
import { mutate } from "swr";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { Button, Loader, Text } from "rimble-ui";
import { baseColors, ModalContent, ModalHeader, SertoUiContext } from "serto-ui";

export interface MemberDeleteProps {
  user: UserUpdateTypes;
  close(): void;
}

export const MemberDelete: React.FunctionComponent<MemberDeleteProps> = (props) => {
  const { toastProvider } = useContext(SertoUiContext);
  const SertoAgent = useContext(SertoAgentContext);
  const [loading, setLoading] = useState<boolean>(false);

  const deleteMember = async () => {
    setLoading(true);
    try {
      await SertoAgent.deleteUser(props.user.id);
      mutate(SertoAgent.api.USERS);
      toastProvider.addMessage(props.user.name + " has been removed", {
        colorTheme: "light",
        variant: "success",
      });
      props.close();
      return;
    } catch (err) {
      console.error("failed to delete member:", err);
    }
    setLoading(false);
  };

  return (
    <>
      <ModalHeader>{props.user.name}</ModalHeader>
      <ModalContent>
        <Text mb={2}>
          <b>Username:</b> {props.user.username}
        </Text>
        <Text mb={5}>
          <b>Role:</b> {props.user.role === "admin" ? "Admin" : "User"}
        </Text>
        <Button onClick={deleteMember} disabled={loading} width="100%" variant="danger">
          {loading ? <Loader color={baseColors.white} /> : "Remove Member"}
        </Button>
      </ModalContent>
    </>
  );
};
