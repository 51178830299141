import { useState } from "react";
import styled from "styled-components";
import { CheckBox, CheckBoxOutlineBlank } from "@rimble/icons";
import { Box, Flex } from "rimble-ui";
import { baseColors, colors } from "serto-ui";
import { DidViewAlias } from "../../components";

export const DidCheckBoxContain = styled(Box)`
  &:hover {
    border: 1px solid ${colors.primary.base};
  }
  input {
    display: none;
  }
`;

export interface DidCheckBoxProps {
  did: any;
  name: string;
  onChange?(e: any): void;
}

export const DidCheckBox: React.FunctionComponent<DidCheckBoxProps> = (props) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  return (
    <DidCheckBoxContain
      bg={isChecked ? colors.primary.background : baseColors.white}
      border={isChecked ? 3 : 2}
      borderRadius={1}
      mb={2}
    >
      <input
        checked={isChecked}
        id={props.did.did}
        name={props.name}
        onChange={(e: any) => {
          setIsChecked(!isChecked);
          props.onChange && props.onChange(e);
        }}
        type="checkbox"
        value={props.did.did}
      />
      <label htmlFor={props.did.did}>
        <Flex alignItems="center" p={3} style={{ cursor: "pointer" }}>
          {isChecked ? (
            <CheckBox color={colors.primary.base} mr={3} />
          ) : (
            <CheckBoxOutlineBlank color={colors.primary.base} mr={3} />
          )}
          <Box width="calc(100% - 80px)">
            <DidViewAlias alias={props.did.alias} did={props.did.did} inline />
          </Box>
        </Flex>
      </label>
    </DidCheckBoxContain>
  );
};
