import { Eth, CheckCircle, Warning } from "@rimble/icons";
import { Box, Flex, Text } from "rimble-ui";
import { colors, DidKeyIcon, DidWebIcon } from "serto-ui";

export interface DidMethodOptionTitleProps {
  didMethod: string;
  showMessaging: boolean;
  showRecommended?: boolean;
}

export const DidMethodOptionTitle: React.FunctionComponent<DidMethodOptionTitleProps> = (props) => {
  return (
    <Box pl={4} position="relative" width="100%">
      {props.didMethod === "did:key" && (
        <DidMethodOptionTitleStyled
          icon={<DidKeyIcon />}
          label="DID Key (did:key)"
          messagingAvailable={false}
          showMessaging={props.showMessaging}
        />
      )}
      {props.didMethod === "did:web" && (
        <DidMethodOptionTitleStyled
          icon={<DidWebIcon />}
          label="DID Web (did:web)"
          messagingAvailable={true}
          showMessaging={props.showMessaging}
          showRecommended={props.showRecommended}
        />
      )}
      {props.didMethod === "did:ethr" && (
        <DidMethodOptionTitleStyled
          icon={<Eth color="#637EEA" size="16px" />}
          label="Ethereum DID (did:ethr)"
          messagingAvailable={false}
          showMessaging={props.showMessaging}
        />
      )}
      {props.didMethod === "did:ethr:rinkeby" && (
        <DidMethodOptionTitleStyled
          icon={<Eth color="#F6C343" size="16px" />}
          label="Rinkeby Ethereum DID (did:ethr:rinkeby)"
          messagingAvailable={false}
          showMessaging={props.showMessaging}
        />
      )}
    </Box>
  );
};

export interface DidMethodOptionTitleStyledProps {
  icon: JSX.Element;
  label: string;
  messagingAvailable: boolean;
  showMessaging: boolean;
  showRecommended?: boolean;
}

export const DidMethodOptionTitleStyled: React.FunctionComponent<DidMethodOptionTitleStyledProps> = (props) => {
  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Box>
        <DidMethodOptionIcon>{props.icon}</DidMethodOptionIcon>
        <Text>{props.label}</Text>
      </Box>
      {props.showMessaging && (
        <>
          {props.messagingAvailable ? (
            <CheckCircle color={colors.success.base} size="20px" />
          ) : (
            <Warning color={colors.warning.base} size="20px" />
          )}
        </>
      )}
      {props.showRecommended && (
        <>
          <Text color={colors.success.base} fontSize={2}>
            Recommended
          </Text>
        </>
      )}
    </Flex>
  );
};

export const DidMethodOptionIcon: React.FunctionComponent = (props) => {
  return (
    <Box left="0" position="absolute" top="4px">
      {props.children}
    </Box>
  );
};

export const DidMethodOptionDescription: React.FunctionComponent = (props) => {
  return (
    <Text color={colors.midGray} fontSize={0} pl={4}>
      {props.children}
    </Text>
  );
};

export interface DidMethodOptionProps {
  didMethod: string;
}

export const DidMethodOption: React.FunctionComponent<DidMethodOptionProps> = (props) => {
  return (
    <Box>
      {props.didMethod === "did:key" && (
        <>
          <DidMethodOptionTitle didMethod={props.didMethod} showMessaging={true} />
          <DidMethodOptionDescription>
            A ledger-independent method based on public/private key pairs.
          </DidMethodOptionDescription>
        </>
      )}
      {props.didMethod === "did:web" && (
        <>
          <DidMethodOptionTitle didMethod={props.didMethod} showMessaging={true} />
          <DidMethodOptionDescription>
            This method lets your ownership of a domain and its DNS become a decentralized identifier (DID). It also
            supports messaging, allowing DIDs to receive free messages and credentials.
          </DidMethodOptionDescription>
        </>
      )}
      {props.didMethod === "did:ethr" && (
        <>
          <DidMethodOptionTitle didMethod={props.didMethod} showMessaging={true} />
          <DidMethodOptionDescription>
            This method allows any Ethereum smart contract or key pair account to become a decentralized identifier
            (DID).
          </DidMethodOptionDescription>
        </>
      )}
      {props.didMethod === "did:ethr:rinkeby" && (
        <>
          <DidMethodOptionTitle didMethod={props.didMethod} showMessaging={true} />
          <DidMethodOptionDescription>
            This method allows any Ethereum smart contract or key pair account deployed on the Rinkeby network to become
            a decentralized identifier (DID).
          </DidMethodOptionDescription>
        </>
      )}
    </Box>
  );
};
