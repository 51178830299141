import { routes, sections } from "../../constants";
import { DIDManagement } from "./DIDManagement";
import { Header, HeaderBox } from "serto-ui";
import { ContentBox, Global } from "../../components";

export const IdentifiersPage: React.FunctionComponent = () => {
  return (
    <Global section={sections.IDENTIFIERS} url={routes.IDENTIFIERS}>
      <HeaderBox>
        <Header heading="Identifiers" />
      </HeaderBox>
      <ContentBox>
        <DIDManagement />
      </ContentBox>
    </Global>
  );
};
