import { useContext, useState } from "react";
import useSWR from "swr";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { AddCircle, Check, Error, Warning } from "@rimble/icons";
import { Box, Button, Table, Tooltip } from "rimble-ui";
import { colors, DidView, ModalWithX, TBody, TH, THead, TR, Identifier } from "serto-ui";
import { ErrorMsgIdentifiers, IsError, IsValidating, NoDIDsText, NoData, SecondaryHeader } from "../../components";
import { DIDManagementCreate } from "./DIDManagementCreate";
import { DIDManagementEdit } from "./DIDManagementEdit";

export const DIDManagement: React.FunctionComponent = () => {
  const SertoAgent = useContext(SertoAgentContext);
  const [isCreateDidModalOpen, setIsCreateDidModalOpen] = useState<boolean>(false);
  const [isEditAliasModalOpen, setIsEditAliasModalOpen] = useState<boolean>(false);
  const [didToEdit, setDidToEdit] = useState<any>({});

  const { data: identifiers, error, isValidating } = useSWR(
    SertoAgent.api.VERAMO_GET_DIDS,
    () => SertoAgent.getDids(),
    {
      revalidateOnFocus: false,
    },
  );
  const { data: didMethods } = useSWR(SertoAgent.api.SETTINGS_DID_METHODS, () => SertoAgent.getDidMethods(), {
    revalidateOnFocus: false,
  });

  const onCloseModal = () => {
    setIsCreateDidModalOpen(false);
    setIsEditAliasModalOpen(false);
  };

  return (
    <>
      <SecondaryHeader
        heading="My Identifiers (DIDs)"
        cta={
          <>
            {didMethods && (
              <Button onClick={() => setIsCreateDidModalOpen(true)} size="small">
                <AddCircle size="14px" mr={1} color={colors.primary.disabled[1]} />
                Create DID
              </Button>
            )}
          </>
        }
      />

      {isValidating ? (
        <IsValidating />
      ) : error ? (
        <IsError error={<ErrorMsgIdentifiers error={error} />} />
      ) : identifiers && identifiers.length > 0 ? (
        <Table border={0} boxShadow={0} width="100%">
          <THead>
            <TR>
              <TH>Address</TH>
              <TH>Alias</TH>
              <TH>
                <div style={{ textAlign: "center" }}>Messaging Enabled</div>
              </TH>
              <TH />
            </TR>
          </THead>
          <TBody>
            {identifiers.map((identifier: Identifier, i: number) => {
              return (
                <TR key={i}>
                  <td>
                    <Box maxWidth="300px">
                      <DidView did={identifier.did} copy />
                    </Box>
                  </td>
                  <td>{identifier.alias}</td>
                  <td style={{ textAlign: "center" }}>
                    {identifier.services?.length ? (
                      <Check color={colors.success.base} />
                    ) : identifier.provider !== "did:web" ? (
                      <Tooltip
                        message={`Messaging not currently supported for the ${identifier.provider} DID method`}
                        placement="top"
                      >
                        <Warning color={colors.warning.base} />
                      </Tooltip>
                    ) : (
                      <Error color={colors.danger.base} />
                    )}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {!identifier.did.includes("did:web") && (
                      <Button.Outline
                        onClick={() => {
                          setDidToEdit(identifier);
                          setIsEditAliasModalOpen(true);
                        }}
                        size="small"
                      >
                        Edit
                      </Button.Outline>
                    )}
                  </td>
                </TR>
              );
            })}
          </TBody>
        </Table>
      ) : (
        <NoData text={<NoDIDsText />} />
      )}

      <ModalWithX
        isOpen={isCreateDidModalOpen}
        close={() => setIsCreateDidModalOpen(false)}
        borderRadius={2}
        width="650px"
      >
        <DIDManagementCreate close={() => onCloseModal()} didMethods={didMethods} />
      </ModalWithX>

      <ModalWithX
        isOpen={isEditAliasModalOpen}
        close={() => {
          setIsEditAliasModalOpen(false);
          setDidToEdit("");
        }}
        borderRadius={2}
        width="480px"
      >
        <DIDManagementEdit did={didToEdit} close={() => onCloseModal()} />
      </ModalWithX>
    </>
  );
};
