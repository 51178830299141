import { useContext, useState } from "react";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { ModalContent, ModalHeader, SertoUiContext } from "serto-ui";
import { ErrorMsgMemberManagement } from "../../components";
import { Password } from "../Auth/Password";

export interface ProfileResetPasswordProps {
  close(): void;
}

export const ProfileResetPassword: React.FunctionComponent<ProfileResetPasswordProps> = (props) => {
  const { toastProvider } = useContext(SertoUiContext);
  const SertoAgent = useContext(SertoAgentContext);
  const [error, setError] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const changePassword = async (password: string) => {
    setError("");
    setLoading(true);

    try {
      await SertoAgent.changePassword(password);
      toastProvider.addMessage("Your password has been updated", {
        colorTheme: "light",
        variant: "success",
      });
      props.close();
      return;
    } catch (err) {
      console.error("failed to reset password:", err);
      setError(<ErrorMsgMemberManagement error={err.message} />);
    }
    setLoading(false);
  };

  return (
    <>
      <ModalHeader>Reset Password</ModalHeader>
      <ModalContent>
        <Password
          cancel={props.close}
          error={error}
          loading={loading}
          setPassword={(password: string) => changePassword(password)}
        />
      </ModalContent>
    </>
  );
};
