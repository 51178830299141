import { useContext, useState } from "react";
import { mutate } from "swr";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { Box, Button, Field, Flash, Form, Input } from "rimble-ui";
import { truncateDid, Identifier, ModalContent, ModalHeader } from "serto-ui";
import { DIDWebDomainExplainerText, AliasExplainerText, ErrorMsgIdentifiers } from "../../components/text";
import { config } from "../../config";

export interface DIDManagementEditProps {
  did: Identifier;
  close(): void;
}

export const DIDManagementEdit: React.FunctionComponent<DIDManagementEditProps> = (props) => {
  const SertoAgent = useContext(SertoAgentContext);
  const [alias, setAlias] = useState<string>(props.did.alias || "");
  const [error, setError] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  async function editAlias(e: Event) {
    e.preventDefault();
    setError("");
    setLoading(true);
    try {
      await SertoAgent.setDidAlias(props.did.did, alias);
      await mutate(SertoAgent.api.VERAMO_GET_DIDS);
      props.close();
      return;
    } catch (err) {
      console.error("failed to set alias:", err);
      setError(<ErrorMsgIdentifiers error={err.message} />);
    }
    setLoading(false);
  }

  return (
    <>
      <ModalHeader style={{ textOverflow: "ellipsis", overflow: "hidden" }} title={props.did.did}>
        {truncateDid(props.did.did)}
      </ModalHeader>
      <ModalContent>
        <Form onSubmit={editAlias}>
          <Box mb={3}>
            <Field label={props.did.provider === "did:web" ? "Domain Name" : "Alias"} mb={0} width="100%">
              <Input
                type="text"
                placeholder={alias || "My Primary DID"}
                value={alias || ""}
                onChange={(e: any) => setAlias(e.target.value)}
                width="100%"
                required={props.did.provider === "did:web"}
                disabled={props.did.provider === "did:web"}
              />
            </Field>
            {props.did.provider === "did:web" ? (
              <DIDWebDomainExplainerText domain={alias} agentDomain={config.AGENT_API_URL.replace(/https?:\/\//, "")} />
            ) : (
              <AliasExplainerText />
            )}
          </Box>
          {error && (
            <Flash mb={3} variant="danger">
              {error}
            </Flash>
          )}
          <Button disabled={loading} type="submit" width="100%">
            {props.did.provider === "did:web" ? "Close" : "Update"}
          </Button>
        </Form>
      </ModalContent>
    </>
  );
};
