import { useContext } from "react";
import { SertoAgentContext } from "../../context/SertoAgentProvider";
import { Redirect, Route } from "react-router-dom";
import { routes } from "../../constants";
import { Flex, Loader } from "rimble-ui";

export interface AuthenticatedRouteProps {
  redirect?: string;
  [key: string]: any;
}

export const AuthenticatedRoute = ({ redirect, ...otherProps }: AuthenticatedRouteProps): JSX.Element => {
  const SertoAgent = useContext(SertoAgentContext);

  if (SertoAgent.loggingIn) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100vh">
        <Loader size={5} />
      </Flex>
    );
  }

  if (!SertoAgent.isAuthenticated()) {
    if (!redirect) {
      SertoAgent.logout();
    }
    return <Redirect to={redirect || routes.LOGIN} />;
  }

  return <Route {...otherProps} />;
};
